import { useState, useEffect } from 'react'

import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import Loader from 'react-loader-spinner'

import Button from '@mui/material/Button'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import IconButton from '@mui/material/IconButton'
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import GetAppIcon from '@mui/icons-material/GetApp'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

import { eduPaymentDashboard, eduOrderHistory, eduPaymentReceipt } from 'services/api/course_payment/index'
import HeaderEdu from 'modules/edu/components/Header/view/HeaderEdu'
import CustomHelmet from 'components/smart/CustomHelmet'
import Footer from 'components/dumb/Footer'
import ProgressBar from 'components/smart/ProgressBar'

const mui = {
  myClassBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 213,
    fontFamily: 'Poppins',
    fontSize: '18px',
    textTransform: 'none',
    background: 'white',
    color: '#1B8379',
    borderRadius: '7px',
    boxShadow: 'none',
    padding: '4px 23px',
    '&:hover': {
      background: '#E2EAE9',
      boxShadow: 'none',
    },
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
  },

  myClassBtnActive: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 213,
    fontFamily: 'Poppins',
    fontSize: '18px',
    textTransform: 'none',
    fontWeight: '600',
    background: '#E2EAE9',
    color: '#1B8379',
    borderRadius: '7px',
    boxShadow: 'none',
    padding: '4px 23px',
    '&:hover': {
      background: '#E2EAE9',
      boxShadow: 'none',
    },
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
  },
  paymentTab: {
    fontFamily: 'Poppins',
    fontSize: '18px',
    textTransform: 'none',
    fontWeight: '600',
    background: 'white',
    color: '#808080',
    borderRadius: '7px',
    boxShadow: 'none',
    padding: '4px 23px',
    '&:hover': {
      background: '#E2EAE9',
      color: '#1B8379',
      boxShadow: 'none',
    },
    '&:focus': {
      background: '#E2EAE9',
      color: '#1B8379',
      boxShadow: 'none',
    },
  },

  activePaymentTab: {
    background: '#E2EAE9',
    color: '#1B8379',
  },

  applicationBtn: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: 213,
    fontFamily: 'Poppins',
    fontSize: '18px',
    textTransform: 'none',
    fontWeight: '300',
    background: 'transparent',
    color: '#1B8379',
    borderRadius: '7px',
    boxShadow: 'none',
    padding: '4px 23px',
    '&:hover': {
      background: '#E2EAE9',
      boxShadow: 'none',
    },
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
  },
  payNowBtn: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    textTransform: 'none',
    fontWeight: '600',
    background: '#FCD94E',
    color: '#1B8379',
    borderRadius: '11px',
    boxShadow: 'none',
    padding: '8px 16px',
    width: 'auto',
    '&:hover': {
      background: '#FCD94E',
      boxShadow: 'none',
    },
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
    '&:disabled': {
      background: '#DFF3E7',
      boxShadow: 'none',
      color: '#27AE60',
    },
  },

  tableHead: {
    backgroundColor: '#E2F2F0',
    borderRadius: '50px !important',
    '& .MuiTableRow-head': {
      borderRadius: '50px !important',
    },
  },
}

const Payment = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [currentTab, setCurrentTab] = useState('outstanding')

  // var scroll = Scroll.animateScroll
  const [paymentList, setPaymentList] = useState([])
  const [orderHistory, setOrderHistory] = useState([])

  const drawerDisplayed = 'transform translate-x-0 transition duration-1000'

  useEffect(() => {
    window.scrollTo(0, 0)
    getEduPaymentDashboard()
    getEduOrderHIstory()
  }, [])

  const getEduPaymentDashboard = async () => {
    try {
      setLoading(true)
      const res = await eduPaymentDashboard()
      setPaymentList(res.data)
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  const getEduOrderHIstory = async () => {
    try {
      setLoading(true)
      const res = await eduOrderHistory()
      setOrderHistory(res.data)
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  const handleClickDownloadIcon = async (orderId) => {
    try {
      const res = await eduPaymentReceipt(orderId)
      const receiptName = res.headers['content-disposition'].substring(21)
      const element = document.createElement('a')
      const file = new Blob([res.data], {
        type: res.headers['content-type'],
      })
      element.href = URL.createObjectURL(file)
      element.download = `${receiptName}`
      document.body.appendChild(element)
      element.click()
    } catch (error) {
      if (error.status === 400) {
        alert(error.data.message)
      }
      if (error.status === 500) {
        alert('Order Id is invalid')
      }
      console.error(error)
    }
  }

  return (
    <div className="bg-adx-gray">
      <CustomHelmet title="Payment | adnexio EDU" url="https://adnexio.jobs/upskill-payment" />

      {loading === true && (
        <>
          <div
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: '999999999',
            }}
          >
            <Loader type="Oval" color="#09b7b7" height={55} width={55} />
          </div>
          <div
            style={{
              position: 'fixed',
              zIndex: '5',
              width: '100vw',
              height: '100vh',
              background: 'black',
              opacity: '0.7',
            }}
          ></div>
        </>
      )}
      <HeaderEdu page="my-classes" />
      <div className="hidden min-h-[calc(100vh-80px)] lg:relative lg:grid lg:h-full lg:grid-cols-5 lg:bg-adx-gray">
        <div className={`h-full w-full bg-white ${drawerDisplayed}`}>
          <div className="mt-9 flex w-full justify-center">
            <Button variant="contained" sx={mui.myClassBtn} onClick={() => navigate('/my-classes')}>
              <div>
                <DesktopWindowsIcon className="mr-2" />
                My Classes
              </div>
              <ArrowForwardIosIcon className="ml-2 opacity-0" />
            </Button>
          </div>
          <div className="mt-9 flex w-full justify-center">
            <Button variant="contained" sx={mui.myClassBtnActive}>
              <div>
                <AccountBalanceWalletIcon className="mr-2" />
                Payment
              </div>
              <ArrowForwardIosIcon className="ml-2" />
            </Button>
          </div>
        </div>
        {/* Animation to space the column with the left side bar */}
        <div className=" lg:col-span-4 lg:mx-auto lg:w-5/6">
          <div className="flex gap-4 py-9">
            <Button
              variant="contained"
              sx={currentTab === 'outstanding' ? { ...mui.paymentTab, ...mui.activePaymentTab } : { ...mui.paymentTab }}
              onClick={() => setCurrentTab('outstanding')}
            >
              Outstanding
            </Button>
            <Button
              variant="contained"
              sx={currentTab === 'transaction' ? { ...mui.paymentTab, ...mui.activePaymentTab } : { ...mui.paymentTab }}
              onClick={() => setCurrentTab('transaction')}
            >
              Transaction
            </Button>
          </div>

          {currentTab === 'outstanding' ? (
            paymentList.length === 0 && !loading ? (
              <div className="flex h-3/5 items-center justify-center">
                <p className="text-2xl font-bold">No outstanding payment</p>
              </div>
            ) : (
              paymentList.map((item, index) => {
                const lastPaymentDate = item.last_payment_date ? moment(item.last_payment_date).format('DD MMM YYYY') : 'N/A'
                const nextAmountDue = item.next_amount_due ? `${item.currency_code} ${item.next_amount_due}` : 'N/A'
                const nextDueDate = item.next_due_date ? moment(item.next_due_date).format('DD MMM YYYY') : 'N/A'

                return (
                  <div key={index} className="mb-8 grid gap-2 rounded-lg bg-white p-8">
                    <div className="mb-4 flex items-center justify-between ">
                      <div className="flex w-10/12 items-center space-x-4">
                        <img className="h-16 w-16 rounded-full object-cover" src={item.course_logo} alt="logo" />
                        <p className="flex-1 text-3xl font-semibold text-adx-darkGreen">{item.course_title} </p>
                      </div>
                      {!item.first_payment && (
                        <Button sx={mui.payNowBtn} onClick={() => navigate(`/payment-summary/${item.id}`)} disabled={Boolean(item.payment_settled)}>
                          {!Boolean(item.payment_settled) ? (
                            'Pay Now'
                          ) : (
                            <>
                              <CheckCircleIcon className="mr-2" />
                              Paid
                            </>
                          )}
                        </Button>
                      )}
                    </div>
                    <div className="gap-8 lg:grid lg:grid-cols-3">
                      <div className="relative w-full justify-self-center rounded-xl bg-adx-gray px-4 py-8">
                        {!item.payment_settled && <div className="absolute top-0 left-0 h-2 w-full rounded-t-xl bg-adx-darkGreen"></div>}
                        <p className="font-semibold text-adx-darkerGray">{item.payment_settled ? 'Amount Paid' : 'Balance'}</p>
                        <p className="text-3xl font-semibold text-adx-darkGreen">
                          {item.currency_code} {item.payment_settled ? item.amount_paid : item.balance}
                        </p>
                        {item.nex_edu_payment_option_id === 1 ? (
                          <p className="text-xs italic text-adx-darkerGray">One-off</p>
                        ) : item.nex_edu_payment_option_id === 2 && !item.payment_settled ? (
                          <div className="mt-2 flex w-full items-center">
                            <ProgressBar
                              value={(item.num_payments_made / item.total_num_instalments) * 100}
                              sx={{
                                height: 16,
                                backgroundColor: 'white',
                                flex: 1,
                                marginRight: 3,
                              }}
                            />
                            <p className="text-xs text-adx-darkerGray">
                              {item.num_payments_made}/{item.total_num_instalments} paid
                            </p>
                          </div>
                        ) : item.nex_edu_payment_option_id === 2 && item.payment_settled ? (
                          <p className="text-xs italic text-adx-darkerGray">via Installment</p>
                        ) : null}
                      </div>
                      {!item.first_payment && (
                        <div className="relative w-full justify-self-center rounded-xl bg-adx-gray py-8 pl-4">
                          {!item.payment_settled && <div className="absolute top-0 left-0 h-2 w-full rounded-t-xl bg-adx-darkGreen"></div>}
                          <p className="font-semibold text-adx-darkerGray">Last Payment</p>
                          <p className="text-3xl font-semibold text-adx-darkGreen">{lastPaymentDate}</p>
                        </div>
                      )}

                      {!item.first_payment && !item.payment_settled ? (
                        <div className="relative w-full justify-self-center rounded-xl bg-adx-gray py-8 pl-4">
                          {!item.payment_settled && <div className="absolute top-0 left-0 h-2 w-full rounded-t-xl bg-adx-darkGreen"></div>}
                          <p className="font-semibold text-adx-darkerGray">Amount Due</p>
                          <p className="text-3xl font-semibold text-adx-darkGreen">{nextAmountDue}</p>
                          {item.next_due_date && <p className="text-xs italic text-adx-darkerGray">by {nextDueDate}</p>}
                        </div>
                      ) : !item.first_payment && item.payment_settled ? (
                        <div className="relative w-full justify-self-center rounded-xl bg-adx-gray py-8 pl-4"></div>
                      ) : null}

                      {item.first_payment && (
                        <div className="item-center relative col-span-2 flex w-full flex-col justify-center justify-self-center rounded-xl pl-4">
                          <p className="mb-4 px-8 text-left">Make a lump-sum payment at the beginning of the course, or pay by installments!</p>
                          <div className="flex items-center px-8">
                            <Button sx={mui.payNowBtn} onClick={() => navigate(`/payment-summary/${item.id}`)}>
                              Make Payment
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )
              })
            )
          ) : currentTab === 'transaction' ? (
            orderHistory.length === 0 && !loading ? (
              <div className="flex h-3/5 items-center justify-center">
                <p className="text-2xl font-bold">No transaction history</p>
              </div>
            ) : (
              <div>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead sx={mui.tableHead}>
                    <TableRow>
                      <TableCell width="20%">Date</TableCell>
                      <TableCell width="17.5%" align="left">
                        Receipt No.
                      </TableCell>
                      <TableCell width="37.5%" align="left">
                        Items
                      </TableCell>
                      <TableCell width="17.5%" align="left">
                        Amount
                      </TableCell>
                      <TableCell width="7.5%" align="left"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="">
                    {orderHistory.map((item, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell width="20%">{item.formatted_date}</TableCell>
                          <TableCell width="17.5%" align="left">
                            {item.order_id}
                          </TableCell>
                          <TableCell width="37.5%" align="left">
                            {item.course_title}{' '}
                          </TableCell>
                          <TableCell width="17.5%" align="left">
                            {item.currency_code} {item.amount_paid}
                          </TableCell>
                          <TableCell width="7.5%" align="left">
                            <IconButton onClick={() => handleClickDownloadIcon(item.order_id)} size="large">
                              <GetAppIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </div>
            )
          ) : null}
        </div>
      </div>
      <div className="flex h-screen items-center justify-center lg:hidden">
        <div className="mx-16 text-center font-semibold">Please use the web browser to see your payment details</div>
      </div>
      <Footer />
    </div>
  )
}

export default Payment
