import React from 'react'
import { Navigate, useParams } from 'react-router-dom'
import Cookies from 'universal-cookie'
import moment from 'moment'
import EDUSignIn from 'modules/edu/pages/sign_in/views/EDUSignIn'

function EduRedirectSocialAuth() {
  const cookies = new Cookies()
  let { status, value, expired_at } = useParams()

  if (status === 'success') {
    const expires = moment(new Date()).add(30, 'days')._d
    const domain = process.env.REACT_APP_HR_URL

    cookies.set('access_token', value, {
      path: '/',
      expires,
      domain,
    })

    cookies.set('token_expired', expired_at, {
      path: '/',
      expires,
      domain,
    })
    return <Navigate to="/" />
  } else {
    const error = value.replaceAll('_', ' ')
    if (
      error ===
      'This email was used to create account with banned previously. Please proceed to sign in with banned'
    ) {
      return <EDUSignIn data={{ status: status, value: 'Your account has been blocked.' }} />
    } else if (error === 'This email was used to create account with email registration') {
      return (
        <EDUSignIn
          data={{
            status: status,
            value:
              'This email was used to create account with email registration. Please login using your email and password.',
          }}
        />
      )
    } else {
      return <EDUSignIn data={{ status, value }} />
    }
  }
}

export default EduRedirectSocialAuth
