import { useState, useEffect, useRef, useContext } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Loader from 'react-loader-spinner'

import { authContext } from 'contexts/AuthContext'
import { ProfileContext } from 'contexts/ProfileContext'
import { NotificationContext } from 'contexts/NotificationContext'
import { notificationRead } from 'services/api/notification'
import { clearCookies } from 'services/helper/clearCookies'
import { signout } from 'services/api/authentication'

import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import ButtonBase from '@mui/material/ButtonBase'
import Grow from '@mui/material/Grow'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Badge from '@mui/material/Badge'
import useMediaQuery from '@mui/material/useMediaQuery'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded'
import LogoutIcon from '@mui/icons-material/Logout'
import PersonIcon from '@mui/icons-material/Person'
import { Box } from '@mui/material'

import AdnexioWhite from 'assets/logos/adnexio-logo-white.png'
import AdnexioEduV2 from 'assets/logos/adnexio-logo-edu-v2-aug.png'
import DefaultProfile from 'assets/images/jobseeker.jpg'
import SettingsModal from '../../../../../components/smart/SettingsModal'
import DeleteAccount from 'pages/profile/components/DeleteAccount'

const mui = {
  notiCloseIconBtn: {
    color: 'white',
    '&:focus': {
      outline: 'none',
    },
  },
  notiCloseIcon: {
    color: 'white',
  },
  notiIconBtn: {
    color: '#1B8379',
    '&:focus': {
      outline: 'none',
    },
    padding: '5px',
    marginRight: '2px',
    '@media (min-width:1024px)': {
      marginRight: '20px',
    },
  },
  notiIcon: {
    fontSize: '25px',
    transform: 'rotate(15deg)',
    '@media (min-width:1024px)': {
      fontSize: '30px',
    },
  },
  loginBtn: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    textTransform: 'none',
    fontWeight: '600',
    background: '#FCD94E',
    color: '#1B8379',
    borderRadius: '5px',
    boxShadow: 'none',
    padding: '5px 20px',
    '&:hover': {
      background: '#FCD94E',
      boxShadow: 'none',
    },
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
  },
  interviewnBtn: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    textTransform: 'none',
    fontWeight: '500',
    background: '#FCD94E',
    color: '#1B8379',
    boxShadow: 'none',
    padding: '5px 20px',
    '&:hover': {
      background: '#FCD94E',
      boxShadow: 'none',
    },
  },
  signUpBtn: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    textTransform: 'none',
    fontWeight: 'normal',
    color: '#FFFFFF',
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
  },
  onFocusButton: {
    color: '#FCD94E',
    fontWeight: '500',
  },
  upskillBtn: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    textTransform: 'none',
    fontWeight: 'normal',
    color: '#F6DC74',
    backgroundColor: '#228E84',
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
  },
  searchBar: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    color: '#959595',
  },
  searchIcon: {
    color: '#959595',
    fontSize: '27px',
  },
  mobileMenuBtn: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    textTransform: 'none',
    fontWeight: 'normal',
    color: '#FFFFFF',
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
  },
  appBtn: {
    outline: 'none',
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
  },
  notiBtn: {
    textAlign: 'left',
    fontWeight: '300',
    outline: 'none',
    width: '100%',
    padding: '20px 0px',
    justifyContent: 'flex-start',
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
    '&:hover': {
      backgroundColor: '#F9F9F9',
    },
  },
  badgeCustom: {
    '& .MuiBadge-badge': {
      '@media (max-width:1024px)': {
        padding: '0px',
        fontSize: '10px',
      },
    },
  },
  settingsBtn: {
    fontSize: '14px !important',
    padding: '15px 40px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  deleteBtn: {
    fontSize: '14px !important',
    padding: '15px 40px',
    color: 'red',
    fontWeight: '600',
    display: 'flex',
    justifyContent: 'space-between',
  },
  closeBtn: {
    color: 'white',
  },
  popper: {
    '& .MuiList-padding': {
      padding: '10px 10px 0px 10px',
    },
  },
  imageBtn: {
    boxShadow: 'none',
    textTransform: 'none',
    fontWeight: '400',
    fontSize: '13px',
  },
  enrollBtn: {
    fontFamily: 'Poppins',
    fontSize: '20px',
    textTransform: 'none',
    fontWeight: '600',
    background: '#FCD94E',
    color: '#1B8379',
    borderRadius: '11px',
    boxShadow: 'none',
    padding: '9px 45px',
    '&:hover': {
      background: '#FCD94E',
      boxShadow: 'none',
    },
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
    marginTop: '56px',
  },
  popperTwo: {
    '& .MuiList-padding': {
      padding: '10px 10px 10px 10px',
    },
  },
  settingsEDUBtn: {
    fontSize: '14px !important',
    color: '#25292e',
  },
  deleteEDUBtn: {
    fontSize: '14px !important',
    color: '#cb2424',
  },
  CoursesBtn: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    textTransform: 'none',
    fontWeight: 'normal',
    color: '#262626',
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
  },
  signUpEduBtn: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    textTransform: 'none',
    fontWeight: 'normal',
    color: '#1B8379',
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
  },
  loginEduBtn: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    textTransform: 'none',
    background: '#1B8379',
    color: '#FFFFFF',
    borderRadius: '5px',
    boxShadow: 'none',
    padding: '5px 20px',
    '&:hover': {
      boxShadow: 'none',
    },
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
  },
}

const HeaderEdu = ({ page, loginMode }) => {
  const isLarge = useMediaQuery((theme) => theme.breakpoints.up('lg'))
  const location = useLocation()
  const navigate = useNavigate()
  const { pathname } = location
  const [openMobileMenu, setOpenMobileMenu] = useState(false)
  const [loading, setLoading] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)

  const { auth, logoutUser } = useContext(authContext)
  const { profileData } = useContext(ProfileContext)
  const { eduNotification, getNotification } = useContext(NotificationContext)

  let isLoggedIn = auth.isAuth
  let profilePhoto = profileData !== null && profileData.profile_image_url

  ///////////////////////////////////////////////////////////////// notification logic
  const [openNotification, setOpenNotification] = useState(false)
  const [openProfile, setOpenProfile] = useState(false)
  const anchorRefNotification = useRef(null)
  const anchorRefProfile = useRef(null)

  const handleToggleNotification = () => {
    setOpenNotification((prevOpen) => !prevOpen)
    setOpenMobileMenu(false)
  }

  const handleCloseNotification = (event) => {
    if (anchorRefNotification.current && anchorRefNotification.current.contains(event.target)) {
      return
    }
    setOpenNotification(false)
  }

  const signUpEdu = () => {
    navigate('/signup', { state: { from: 'edu' } })
  }

  const signInEdu = () => {
    navigate('/signin', { state: { from: 'edu' } })
  }

  function handleListKeyDownNotification(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpenNotification(false)
    }
  }

  // return focus to the button when we transitioned from !openMobileMenu -> openMobileMenu
  const prevOpen = useRef(openNotification)
  useEffect(() => {
    if (prevOpen.current === true && openNotification === false) {
      anchorRefNotification.current.focus()
    }

    prevOpen.current = openNotification
  }, [openNotification])

  // mark as read notification
  const handleReadMessage = async (id) => {
    try {
      await notificationRead(id).then(async () => await getNotification({ isEdu: true }))
    } catch (e) {
      console.error(e)
    }
  }
  ///////////////////////////////////////////////////////////////// end notification logic

  // disable scroll when mobile menu open
  const handleMenu = () => {
    if (openMobileMenu) {
      setOpenMobileMenu(false)
      document.body.style.overflow = 'unset'
    } else if (!openMobileMenu) {
      setOpenMobileMenu(true)
    }
  }

  ///////////////////////////////////////////////////////////////// settings logic
  const [openSetting, setOpenSetting] = useState(false)

  const handleSettings = () => {
    setOpenSetting(true)
    setOpenMobileMenu(false)
  }
  ///////////////////////////////////////////////////////////////// end settings logic

  // reset mobile menu button if window resize
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth >= 1024) {
        setOpenMobileMenu(false)
        document.body.style.overflow = 'unset'
      }
    }
    window.addEventListener('resize', handleResize)
  })

  const handleRedirectProfile = () => {
    setOpenProfile(false)
    navigate('/profile')
  }

  const handleSignOut = async () => {
    try {
      setLoading(true)
      setOpenProfile(false)
      await signout()
      clearCookies()
      logoutUser()
      setLoading(false)
      navigate('/')
    } catch (e) {
      setLoading(false)
      console.error(e)
    }
  }

  const handleDismissProfileModal = (event) => {
    if (anchorRefProfile.current && anchorRefProfile.current.contains(event.target)) {
      return
    }
    setOpenProfile(false)
  }

  return (
    <>
      {(loading || auth.loading) && (
        <>
          <Box
            sx={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: '999999999',
            }}
          >
            <Loader type="Oval" color="#09b7b7" height={55} width={55} />
          </Box>
          <Box
            sx={{
              position: 'fixed',
              zIndex: '999999',
              width: '100vw',
              height: '100vh',
              background: 'black',
              opacity: '0.7',
            }}
          />
        </>
      )}

      {/* Top Header - This is for hamburger menu and close icon */}
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: '80px',
          zIndex: 30,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: '#FFFFFF',
          paddingX: isLarge ? 8 : 2,
          paddingY: 2,
        }}
        className="shadow"
      >
        {loginMode && (
          <>
            <Box sx={{ position: 'absolute' }}>
              {!openMobileMenu && (
                <IconButton
                  sx={{
                    color: '#1B8379',
                    '&:focus': {
                      outline: 'none',
                    },
                  }}
                  onClick={handleMenu}
                  size="large"
                >
                  <MenuRoundedIcon sx={{ fontSize: '30px' }} />
                </IconButton>
              )}

              {openMobileMenu && (
                <IconButton
                  sx={{
                    color: '#1B8379',
                    '&:focus': {
                      outline: 'none',
                    },
                  }}
                  onClick={handleMenu}
                  size="large"
                >
                  <CloseRoundedIcon sx={{ fontSize: '30px' }} />
                </IconButton>
              )}
            </Box>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Link to="/" className="cursor-pointer">
                <img src={AdnexioEduV2} className="w-32 lg:w-40" alt="logo" />
              </Link>
            </Box>
          </>
        )}

        {loginMode === undefined && (
          <>
            <Box sx={{ display: isLarge ? 'none' : 'block' }}>
              {!openMobileMenu && (
                <IconButton
                  sx={{
                    color: '#1B8379',
                    '&:focus': {
                      outline: 'none',
                    },
                  }}
                  onClick={handleMenu}
                  size="large"
                >
                  <MenuRoundedIcon sx={{ fontSize: '30px' }} />
                </IconButton>
              )}

              {openMobileMenu && (
                <IconButton
                  sx={{
                    color: '#1B8379',
                    '&:focus': {
                      outline: 'none',
                    },
                  }}
                  onClick={handleMenu}
                  size="large"
                >
                  <CloseRoundedIcon sx={{ fontSize: '30px' }} />
                </IconButton>
              )}
            </Box>

            <div className="items-center space-x-14 lg:flex">
              <Link to="/" className="cursor-pointer">
                <img src={AdnexioEduV2} className="w-32 lg:w-48" alt="logo" />
              </Link>

              {!isLoggedIn && (
                <div className="flex items-center space-x-12">
                  <Box sx={{ display: isLarge ? 'block' : 'none' }}>
                    <Link to="/courses">
                      <Button sx={mui.CoursesBtn}>Courses</Button>
                    </Link>
                  </Box>
                </div>
              )}

              {isLoggedIn && (
                <div className="flex items-center space-x-12">
                  <Box sx={{ backgroundColor: '#228E84' }} className="flex items-center space-x-12 rounded-full px-4">
                    <Box sx={{ display: isLarge ? 'block' : 'none' }} className="mr-4">
                      <Link to="/my-classes">
                        <Button
                          sx={
                            pathname === '/my-classes' || pathname === '/upskill-payment'
                              ? { ...mui.signUpBtn, ...mui.onFocusButton }
                              : { ...mui.signUpBtn }
                          }
                        >
                          My Dashboard
                        </Button>
                      </Link>
                    </Box>
                    <Box sx={{ display: isLarge ? 'block' : 'none' }} className="mr-4">
                      <Link to="/courses">
                        <Button sx={pathname === '/courses' ? { ...mui.signUpBtn, ...mui.onFocusButton } : { ...mui.signUpBtn }}>Courses</Button>
                      </Link>
                    </Box>
                  </Box>
                </div>
              )}
            </div>

            <div className="mr-2 flex items-center">
              {!isLoggedIn && (
                <>
                  <Box sx={{ display: isLarge ? 'block' : 'none' }} className="mr-4">
                    <Button sx={mui.signUpEduBtn} onClick={signUpEdu}>
                      Sign Up
                    </Button>
                  </Box>
                  <div>
                    <Button onClick={signInEdu} variant="contained" sx={mui.loginEduBtn}>
                      Sign In
                    </Button>
                  </div>
                </>
              )}

              {isLoggedIn && (
                <>
                  <IconButton sx={mui.notiIconBtn} onClick={handleToggleNotification} ref={anchorRefNotification} size="large">
                    <Badge badgeContent={eduNotification !== null && eduNotification?.unreadCount} color="error" max={99} sx={mui.badgeCustom}>
                      <NotificationsRoundedIcon sx={mui.notiIcon} />
                    </Badge>
                  </IconButton>
                  <Popper
                    open={openNotification}
                    anchorEl={anchorRefNotification.current}
                    transition
                    disablePortal
                    placement="bottom-end"
                    className="z-50 w-10/12 max-w-sm pt-1 lg:w-auto lg:min-w-400 lg:max-w-sm"
                    sx={mui.popper}
                  >
                    {({ TransitionProps }) => (
                      <Grow {...TransitionProps} style={{ transformOrigin: 'right top' }}>
                        <div className="rounded-lg border border-gray-200 bg-adx-white pb-4 font-poppins text-xs shadow-md outline-none">
                          <ClickAwayListener onClickAway={handleCloseNotification}>
                            <MenuList autoFocusItem={openNotification} onKeyDown={handleListKeyDownNotification}>
                              <div className="mb-2 flex items-center justify-between rounded-lg bg-adx-darkGreen px-4 outline-none">
                                <div className="text-base font-medium text-adx-white outline-none">Notifications</div>
                                <IconButton sx={mui.notiCloseIconBtn} onClick={handleCloseNotification} size="large">
                                  <CloseRoundedIcon fontSize="small" sx={mui.notiCloseIcon} />
                                </IconButton>
                              </div>
                              {eduNotification !== null && eduNotification.unreadCount === 0 && (
                                <div className="lg:no-scrollbar flex max-h-80 min-h-200 items-center justify-center overflow-y-scroll px-4 pt-2 font-light lg:overflow-y-auto lg:text-sm">
                                  <div className="font-light">You are up to date</div>
                                </div>
                              )}
                              {eduNotification !== null && eduNotification.unreadCount !== 0 && (
                                <div className="lg:no-scrollbar max-h-80 min-h-200 overflow-y-scroll px-4 font-light lg:overflow-y-auto lg:text-sm">
                                  {eduNotification.data.map((item, index) => (
                                    <div key={item.id}>
                                      <ButtonBase sx={mui.notiBtn} onClick={() => handleReadMessage(item.id)}>
                                        {item.photo_url !== null && (
                                          <img
                                            src={item.photo_url}
                                            alt="profile"
                                            className="mr-3 h-8 w-8 rounded-full object-cover shadow-md lg:h-12 lg:w-12"
                                          />
                                        )}
                                        {item.photo_url === null && (
                                          <img
                                            src={AdnexioWhite}
                                            alt="profile"
                                            className="mr-3 h-8 w-8 rounded-full bg-adx-darkGreen object-contain shadow-md lg:h-12 lg:w-12"
                                          />
                                        )}
                                        <div className="flex flex-col">
                                          <div className="text-xs font-semibold">{item.title}</div>
                                          <div className="text-xs">{typeof item.message === 'string' && item.message}</div>
                                        </div>
                                      </ButtonBase>
                                      {eduNotification.data.length !== index + 1 && <hr />}
                                    </div>
                                  ))}
                                </div>
                              )}
                            </MenuList>
                          </ClickAwayListener>
                        </div>
                      </Grow>
                    )}
                  </Popper>

                  <IconButton
                    sx={{ padding: '0px' }}
                    onClick={() => {
                      setOpenProfile((prevOpen) => !prevOpen)
                    }}
                    ref={anchorRefProfile}
                    size="large"
                  >
                    {profileData !== null && (
                      <>
                        {profileData.profile_image_url !== null && profileData.profile_image_url !== undefined && (
                          <img
                            src={profilePhoto}
                            alt="profile_image"
                            className="h-9 w-9 rounded-full object-cover ring-2 ring-gray-100 lg:h-12 lg:w-12"
                          />
                        )}
                        {(profileData.profile_image_url === null || profileData.profile_image_url === undefined) && (
                          <img
                            src={DefaultProfile}
                            alt="profile_image"
                            className="h-9 w-9 rounded-full object-cover ring-2 ring-gray-100 lg:h-12 lg:w-12"
                          />
                        )}
                      </>
                    )}
                  </IconButton>
                  <Popper
                    open={openProfile}
                    anchorEl={anchorRefProfile.current}
                    transition
                    disablePortal
                    placement="bottom-end"
                    className="z-50 w-4/12 min-w-min max-w-sm pt-1 lg:w-auto lg:max-w-sm"
                    sx={mui.popperTwo}
                  >
                    {({ TransitionProps }) => (
                      <Grow {...TransitionProps} style={{ transformOrigin: 'right top' }}>
                        <div className="min-w-[150px] max-w-[300px] rounded-lg border border-gray-200 bg-white shadow">
                          <ClickAwayListener onClickAway={handleDismissProfileModal}>
                            <MenuList autoFocusItem={openProfile}>
                              <div className="mb-2 space-y-1 border-b px-4 pb-3 pt-1 text-sm text-[#607d8b]">
                                <p className="truncate">{profileData.name}</p>
                                <p className="truncate text-xs">{profileData.email}</p>
                              </div>
                              <MenuItem onClick={handleRedirectProfile} sx={mui.settingsEDUBtn}>
                                <PersonIcon sx={{ marginRight: '8px', fontSize: '22px', marginLeft: '-3px' }} />
                                Profile
                              </MenuItem>
                              <MenuItem onClick={handleSignOut} sx={mui.deleteEDUBtn}>
                                <LogoutIcon sx={{ marginRight: '8px', fontSize: '20px' }} /> Sign out
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </div>
                      </Grow>
                    )}
                  </Popper>
                </>
              )}
            </div>
          </>
        )}
      </Box>

      {/* Bottom Header - Courses displayed here*/}

      {openMobileMenu && !isLoggedIn && (
        <Box
          sx={{
            position: 'absolute',
            top: 80,
            left: 0,
            zIndex: 99,
            width: '100%',
            backgroundColor: '#FFFFFF',
          }}
        >
          <Box sx={{ width: '100%', px: 2, py: 2 }}>
            <Link to="/signup">
              <Button
                sx={{
                  fontFamily: 'Poppins',
                  fontSize: '14px',
                  textTransform: 'none',
                  fontWeight: 'normal',
                  color: '##1B8379',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'end',
                  '&:focus': {
                    outline: 'none',
                    boxShadow: 'none',
                  },
                }}
              >
                Sign Up
              </Button>
            </Link>
            <Link to="/courses">
              <Button
                sx={{
                  fontFamily: 'Poppins',
                  fontSize: '14px',
                  textTransform: 'none',
                  fontWeight: 'normal',
                  color: '#1B8379',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'end',
                  '&:focus': {
                    outline: 'none',
                    boxShadow: 'none',
                  },
                }}
              >
                Courses
              </Button>
            </Link>
          </Box>
        </Box>
      )}

      {openMobileMenu && isLoggedIn && (
        <Box
          sx={{
            position: 'absolute',
            top: 80,
            left: 0,
            zIndex: 99,
            width: '100%',
            backgroundColor: '#FFFFFF',
          }}
        >
          <Box sx={{ width: '100%', px: 2, py: 2 }}>
            <Link to="/my-classes">
              <Button
                sx={{
                  fontFamily: 'Poppins',
                  fontSize: '14px',
                  textTransform: 'none',
                  fontWeight: 'normal',
                  color: '#1B8379',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'end',
                  '&:focus': {
                    outline: 'none',
                    boxShadow: 'none',
                  },
                }}
              >
                My Dashboard
              </Button>
            </Link>
            <Link to="/courses">
              <Button
                sx={{
                  fontFamily: 'Poppins',
                  fontSize: '14px',
                  textTransform: 'none',
                  fontWeight: 'normal',
                  color: '#1B8379',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'end',
                  '&:focus': {
                    outline: 'none',
                    boxShadow: 'none',
                  },
                }}
              >
                Courses
              </Button>
            </Link>
            <Button
              sx={{
                fontFamily: 'Poppins',
                fontSize: '14px',
                textTransform: 'none',
                fontWeight: 'normal',
                color: '#1B8379',
                width: '100%',
                display: 'flex',
                justifyContent: 'end',
                '&:focus': {
                  outline: 'none',
                  boxShadow: 'none',
                },
              }}
              onClick={handleSettings}
            >
              Settings
            </Button>
          </Box>
        </Box>
      )}

      <SettingsModal
        openSetting={openSetting}
        setOpenSetting={setOpenSetting}
        loading={loading}
        setLoading={setLoading}
        setOpenDelete={setOpenDelete}
        openDelete={openDelete}
        jobs={false}
      />

      <DeleteAccount setOpenDelete={setOpenDelete} openDelete={openDelete} loading={loading} setLoading={setLoading} />

      {/*Black Overlay when opening mobile view menu */}
      {openMobileMenu && <div className="fixed top-0 left-0 z-20 h-screen w-screen bg-black opacity-70 lg:hidden" onClick={handleMenu} />}
    </>
  )
}

export default HeaderEdu
