import { Link } from 'react-router-dom'
import { clearCookies } from 'services/helper/clearCookies'
import IconButton from '@mui/material/IconButton'
import Dialog from '@mui/material/Dialog'
import MenuItem from '@mui/material/MenuItem'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import MenuList from '@mui/material/MenuList'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

const mui = {
  settingsBtn: {
    fontSize: '12px !important',
    padding: '18px 25px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  deleteBtn: {
    fontSize: '12px !important',
    padding: '18px 25px',
    color: 'red',
    fontWeight: '600',
    display: 'flex',
    justifyContent: 'space-between',
  },
  closeBtn: {
    color: 'white',
  },
  modalSetting: {
    '& .MuiList-padding': {
      padding: '10px 10px 10px 10px',
    },
    '& .MuiPaper-rounded': {
      borderRadius: '14px',
    },
  },
}

const SettingsModal = ({
  openSetting,
  setOpenSetting,
  setLoading,
  openDelete,
  setOpenDelete,
  jobs = true,
}) => {
  // Logout
  const handleSignOut = () => {
    setLoading(true)
    handleClose()
    clearCookies()
    window.open('/', '_self')
  }

  const handleDeleteModal = (e) => {
    setOpenDelete(!openDelete)
    handleClose()
  }

  const handleClose = () => {
    setOpenSetting(false)
  }

  return (
    <>
      <Dialog onClose={handleClose} open={openSetting} sx={mui.modalSetting}>
        <MenuList style={{ minWidth: '290px' }}>
          <div className="flex items-center justify-between rounded-xl bg-adx-darkGreen px-7 py-1">
            <div className="text-base font-medium text-white">Settings</div>
            <IconButton onClick={handleClose} size="large">
              <CloseRoundedIcon fontSize="small" sx={mui.closeBtn} />
            </IconButton>
          </div>
          {jobs && (
            <>
              <Link to="/nex">
                <MenuItem sx={mui.settingsBtn} onClick={handleClose} divider>
                  <div>Who's NEX</div>
                  <ChevronRightRoundedIcon style={{ color: 'gray' }} fontSize="small" />
                </MenuItem>
              </Link>
              <Link to="/faq">
                <MenuItem sx={mui.settingsBtn} onClick={handleClose} divider>
                  <div>Frequently Asked Questions (FAQ)</div>
                  <ChevronRightRoundedIcon style={{ color: 'gray' }} fontSize="small" />
                </MenuItem>
              </Link>
              <Link to="/terms-conditions">
                <MenuItem sx={mui.settingsBtn} onClick={handleClose} divider>
                  <div>Terms and Conditions</div>
                  <ChevronRightRoundedIcon style={{ color: 'gray' }} fontSize="small" />
                </MenuItem>
              </Link>
              <Link to="/privacy-policy">
                <MenuItem sx={mui.settingsBtn} onClick={handleClose} divider>
                  <div>Privacy Policy</div>
                  <ChevronRightRoundedIcon style={{ color: 'gray' }} fontSize="small" />
                </MenuItem>
              </Link>
            </>
          )}
          <Link to={'/contact-us'}>
            <MenuItem sx={mui.settingsBtn} onClick={handleClose} divider>
              <div>Contact Us</div>
              <ChevronRightRoundedIcon style={{ color: 'gray' }} fontSize="small" />
            </MenuItem>
          </Link>
          <MenuItem sx={mui.settingsBtn} onClick={handleDeleteModal} divider>
            <div>Delete My Account</div>
            <ChevronRightRoundedIcon style={{ color: 'gray' }} fontSize="small" />
          </MenuItem>
          <MenuItem sx={mui.deleteBtn} onClick={handleSignOut}>
            <div>Sign Out</div>
            <ChevronRightRoundedIcon style={{ color: 'red' }} fontSize="small" />
          </MenuItem>
        </MenuList>
      </Dialog>
    </>
  )
}

export default SettingsModal
