import { useState } from 'react'
import { Formik, Form, useField } from 'formik'
import * as Yup from 'yup'

import Footer from 'components/dumb/Footer'
import CustomHelmet from 'components/smart/CustomHelmet'
import HeaderEdu from 'modules/edu/components/Header/view/HeaderEdu'
import { support } from 'services/api/registration'

import TextField from '@mui/material/TextField'
import InputMask from 'react-input-mask'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

const MyTextField = ({ placeholder, label, shrink, multiline, ...props }) => {
  const [field, meta] = useField(props)
  const errorText = meta.error && meta.touched ? meta.error : ''
  return (
    <TextField
      placeholder={placeholder}
      label={label}
      InputProps={{
        sx: {
          fontSize: '14px',
          '& input::placeholder': {
            fontSize: '14px',
          },
        },
      }}
      InputLabelProps={{
        sx: {
          fontSize: '14px',
        },
      }}
      {...field}
      helperText={errorText}
      error={!!errorText}
      shrink={shrink}
      fullWidth
      variant="outlined"
      multiline={multiline}
      rows={10}
    />
  )
}

const MyMaskField = ({ placeholder, label, shrink, ...props }) => {
  const [field, meta] = useField(props)
  const errorText = meta.error && meta.touched ? meta.error : ''
  return (
    <InputMask mask="999-99999999999999" maskChar={null} {...field}>
      {(inputProps) => {
        return (
          <TextField
            placeholder={placeholder}
            label={label}
            InputProps={{
              sx: {
                fontSize: '14px',
                '& input::placeholder': {
                  fontSize: '14px',
                },
              },
            }}
            InputLabelProps={{
              sx: {
                fontSize: '14px',
              },
            }}
            {...inputProps}
            helperText={errorText}
            error={!!errorText}
            shrink={shrink}
            type="tel"
            fullWidth
            variant="outlined"
          />
        )
      }}
    </InputMask>
  )
}

const EDUContactUs = () => {
  const [loading, setLoading] = useState(false)
  const [openSnack, setOpenSnack] = useState(false)
  const snackProps = {
    severity: 'success',
    message: 'Your message has been delivered.',
  }
  const closeSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenSnack(false)
  }

  const initialValues = {
    name: '',
    contact_number: '',
    email: '',
    message: '',
  }

  const submitSupport = async (data, setErrors, resetForm) => {
    try {
      setOpenSnack(false)
      setLoading(true)
      await support(data)
      setOpenSnack(true)
      resetForm()
    } catch (e) {
      setErrors(e.data.errors)
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  const validationSchema = Yup.object({
    name: Yup.string().required('This value is required.'),
    contact_number: Yup.string()
      .typeError('This value is required.')
      .required('This value is required.')
      .min(11, 'Phone number must be at least 10 digits')
      .max(18),
    email: Yup.string().required('This value is required.'),
    message: Yup.string().max(500, 'This value is too long. It should have 500 characters or fewer.').required('This value is required.'),
  })

  return (
    <>
      <Snackbar open={openSnack} autoHideDuration={3500} onClose={closeSnack} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert onClose={closeSnack} severity={snackProps.severity} variant="filled">
          {snackProps.message}
        </Alert>
      </Snackbar>

      <CustomHelmet title="Contact Us | adnexio EDU" url="https://adnexio.jobs/contact-us" />

      <HeaderEdu />

      <div className="lg:flex">
        <div className="flex items-center justify-center bg-adx-white lg:w-7/12">
          <div className="my-2 max-w-2xl space-y-8 py-4 px-10 text-sm lg:my-10">
            <div className="space-y-3">
              <div className="text-xl font-semibold text-adx-darkGreen">Contact Us</div>
              <div>If you have any professional queries for us, please do not hesitate to send us a message by filling in your details below:</div>
            </div>

            <Formik
              validateOnChange={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize={true}
              onSubmit={(data, { setErrors, resetForm }) => {
                submitSupport(data, setErrors, resetForm)
              }}
            >
              {({ dirty }) => (
                <Form autoComplete="off">
                  <div className="flex flex-col space-y-6 pl-2">
                    <MyTextField label="Name" name="name" />
                    <div className="space-y-6 lg:flex lg:space-x-2 lg:space-y-0">
                      <MyMaskField label="Phone" name="contact_number" />
                      <MyTextField label="Email" name="email" />
                    </div>
                    <MyTextField label="Message" name="message" multiline={true} />
                  </div>
                  <div className="relative mt-3 flex items-center justify-center">
                    <Button
                      disabled={!dirty || loading}
                      type="submit"
                      variant="contained"
                      color="primary"
                      style={{
                        textTransform: 'none',
                        fontWeight: '400',
                        fontSize: '13px',
                        padding: '5px 20px',
                        boxShadow: 'none',
                      }}
                    >
                      Submit
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center bg-adx-gray lg:h-[calc(100vh-80px)] lg:w-6/12">
          <div className="my-10 w-5/6 rounded-md bg-adx-darkGreen">
            <div className="p-4 text-adx-white">
              <div className="mb-2 text-lg">Find us</div>
              <div className="text-xs font-light">Agensi Pekerjaan Adnexio Sdn Bhd (1202428-H)</div>
              <div className="text-xs font-light">Aras 2-4, 80, The Trillium, Jalan Tasik Utama 5</div>
              <div className="text-xs font-light">57000 Sungai Besi, Kuala Lumpur.</div>
            </div>

            <iframe
              title="adnexio map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63745.99645301865!2d101.6758109948679!3d3.0612866292782104!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc356313ea22a7%3A0x61017cb577369a1b!2sINVOKE+Space!5e0!3m2!1sen!2smy!4v1549902107044"
              style={{
                width: '100%',
                height: 450,
              }}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default EDUContactUs
