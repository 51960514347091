import { ClickAwayListener, Grow, IconButton, MenuItem, MenuList, Popper } from '@mui/material'
import { ProfileContext } from 'contexts/ProfileContext'
import React from 'react'
import { useRef } from 'react'
import { useContext } from 'react'
import { useState } from 'react'
import DefaultProfile from 'assets/images/jobseeker.jpg'
import PersonIcon from '@mui/icons-material/Person'
import LogoutIcon from '@mui/icons-material/Logout'
import { useNavigate } from 'react-router-dom'
import { clearCookies } from 'services/helper/clearCookies'
import { signout } from 'services/api/authentication'
import { authContext } from 'contexts/AuthContext'

const mui = {
  imgIconBtn: {
    color: 'white',
    '&:focus': {
      outline: 'none',
    },
    padding: '5px',
  },
  popperTwo: {
    '& .MuiList-padding': {
      padding: '10px 10px 10px 10px',
    },
  },
  settingsEDUBtn: {
    fontSize: '14px !important',
    color: '#25292e',
  },
  deleteEDUBtn: {
    fontSize: '14px !important',
    color: '#cb2424',
  },
}

const ProfileButton = () => {
  const navigate = useNavigate()
  const { logoutUser } = useContext(authContext)
  const { profileData } = useContext(ProfileContext)
  const [openProfile, setOpenProfile] = useState(false)
  const anchorRefProfile = useRef(null)

  const handleDismissProfileModal = (event) => {
    if (anchorRefProfile.current && anchorRefProfile.current.contains(event.target)) {
      return
    }
    setOpenProfile(false)
  }

  const handleRedirectProfile = () => {
    setOpenProfile(false)
    navigate('/profile')
  }

  const handleSignOut = async () => {
    try {
      setOpenProfile(false)
      await signout()
      clearCookies()
      logoutUser()
      navigate('/')
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      <IconButton
        sx={mui.imgIconBtn}
        onClick={() => {
          setOpenProfile((prevOpen) => !prevOpen)
        }}
        ref={anchorRefProfile}
        size="large"
      >
        <img
          src={profileData?.profile_image_url || DefaultProfile}
          alt="profile_image"
          className="h-8 w-8 rounded-full object-cover lg:h-12 lg:w-12"
        />
      </IconButton>
      <Popper
        open={openProfile}
        anchorEl={anchorRefProfile.current}
        transition
        disablePortal
        placement="bottom-end"
        className="z-50 w-4/12 min-w-min max-w-sm lg:w-auto lg:max-w-sm"
        sx={mui.popperTwo}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'right top' }}>
            <div className="min-w-[150px] max-w-[300px] rounded-lg bg-white shadow">
              <ClickAwayListener onClickAway={handleDismissProfileModal}>
                <MenuList autoFocusItem={openProfile}>
                  <div className="mb-2 space-y-1 border-b px-4 pb-3 pt-1 text-sm text-[#607d8b]">
                    <p className="truncate">{profileData.name}</p>
                    <p className="truncate text-xs">{profileData.email}</p>
                  </div>
                  <MenuItem onClick={handleRedirectProfile} sx={mui.settingsEDUBtn}>
                    <PersonIcon sx={{ marginRight: '8px', fontSize: '22px', marginLeft: '-3px' }} />
                    Profile
                  </MenuItem>
                  <MenuItem onClick={handleSignOut} sx={mui.deleteEDUBtn}>
                    <LogoutIcon sx={{ marginRight: '8px', fontSize: '20px' }} />
                    Sign out
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </div>
          </Grow>
        )}
      </Popper>
    </>
  )
}

export default ProfileButton
