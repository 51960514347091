import { Button } from '@mui/material'
import icon from '../assets/failedImg.png'
import { useNavigate } from 'react-router-dom'

const HRDEligibleFail = ({ nex_edu_payment_schedule_id }) => {
  const navigate = useNavigate()
  const checkOtherOptions = () => {
    navigate(`/payment-summary/${nex_edu_payment_schedule_id}`)
  }
  return (
    <>
      <div className="mx-auto max-w-[90%] md:max-w-[80%]">
        <div className="flex items-center justify-center">
          <img alt="" src={icon} />
        </div>
        <h3 className="leading-[calc(24px * 1.5)] mt-[40px] mb-[16px] text-center text-[24px] font-medium">
          We're sorry, your application is not eligible
        </h3>
        <p className="mb-[16px] text-center text-adx-darkerGray">
          Your application is not eligible for HRD claimable as your company has not contributed to HRD Corp. Please proceed with other payment
          options available to proceed.
        </p>
      </div>

      <Button onClick={checkOtherOptions} variant="outlined" fullWidth sx={{ minHeight: '45px', mt: '30px', mb: '10px' }} color="primary">
        See available options
      </Button>
    </>
  )
}

export default HRDEligibleFail
