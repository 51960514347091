import { Button, Dialog } from '@mui/material'
import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share'

const ShareSocialMedia = (props) => {
  const { jobAdvertisement, handleClose, open, setCopied, copied, edu = false } = props

  const mui = {
    btnShareDesktop: {
      textTransform: 'initial',
      color: 'white',
      backgroundColor: '#1B8379',
      height: 40,
      width: '100%',
      '&:hover': {
        backgroundColor: '#1B8379',
      },
      '&:focus': {
        outline: 'none',
        color: 'none',
        backgroundColor: 'none',
      },
    },
  }

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="lg">
      <div className="item-center flex min-h-[320px] flex-col justify-between p-8 font-poppins lg:w-96">
        <p className="text-center text-lg font-medium">Share this course on social media</p>
        <div className="mb-4">
          <p className="text-center font-semibold capitalize text-adx-darkGreen">
            {!edu ? jobAdvertisement?.position_title : jobAdvertisement?.course_title}
          </p>
          <p className="text-center font-semibold capitalize">{!edu ? jobAdvertisement?.company_name : jobAdvertisement?.trainer_name}</p>
        </div>
        <div className="flex justify-between">
          <FacebookShareButton url={jobAdvertisement?.share_url_web}>
            <FacebookIcon borderRadius="50%" size={48} />
          </FacebookShareButton>

          <LinkedinShareButton url={jobAdvertisement?.share_url_web}>
            <LinkedinIcon borderRadius="50%" size={48} />
          </LinkedinShareButton>

          <TwitterShareButton url={jobAdvertisement?.share_url_web}>
            <TwitterIcon borderRadius="50%" size={48} />
          </TwitterShareButton>

          <WhatsappShareButton url={jobAdvertisement?.share_url_web}>
            <WhatsappIcon borderRadius="50%" size={48} />
          </WhatsappShareButton>
        </div>

        <CopyToClipboard text={jobAdvertisement?.share_url_web} onCopy={() => setCopied(true)}>
          <Button sx={mui.btnShareDesktop}>{!copied ? 'Copy to clipboard' : 'Copied!'}</Button>
        </CopyToClipboard>
      </div>
    </Dialog>
  )
}

export default ShareSocialMedia
