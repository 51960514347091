import { useState, useEffect, useRef } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Loader from 'react-loader-spinner'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'

import Button from '@mui/material/Button'
import Radio from '@mui/material/Radio'
import Checkbox from '@mui/material/Checkbox'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

import { eduPaymentSummary, eduMakePayment } from 'services/api/course_payment/index'
import CustomHelmet from 'components/smart/CustomHelmet'
import AdnexioEduV2 from 'assets/logos/adnexio-logo-edu-v2-aug.png'

const mui = {
  tableCell: {
    padding: '30px 0px',
    fontWeight: 'bold',
  },
  tableCellNoBorderBottom: {
    borderBottom: 'none',
  },
  radioBtn: {
    marginRight: '16px',
  },
  downloadInvoiceBtn: {
    backgroundColor: '#1B8379',
    textTransform: 'capitalize',
    color: 'white',
    boxShadow: 'none',
  },
  payNowBtn: {
    backgroundColor: '#FCD94E',
    width: '100%',
    textTransform: 'capitalize',
    color: '#1B8379',
    boxShadow: 'none',
    '&:hover': {
      color: '#FCD94E',
    },
  },
  returnToDashboardBtn: {
    color: '#3294F6',
    textTransform: 'capitalize',
  },
}

const validationSchema = Yup.object({
  nex_edu_current_enrolment_id: Yup.number().required('Enrollment id is required'),
  amount: Yup.number().required('Please select a payment method'),
  agree_status: Yup.boolean().oneOf([true], 'The terms and conditions must be accepted.'),
})

const PaymentSummary = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const formikRef = useRef()
  const [loading, setLoading] = useState(false)
  const [paymentOptions, setPaymentOptions] = useState(null)
  const [paymentSummary, setPaymentSummary] = useState({})
  const [initialValues, setInitialValues] = useState()

  const [showTnCModal, setShowTnCModal] = useState(false)

  const [showError, setShowError] = useState(false)
  const [textError, setTextError] = useState(false)

  let fullPaymentDiscount = paymentSummary?.applicable_discounts?.find((item) => item?.name === 'Full Payment')

  const handlePaymentOptionsChange = (e) => {
    setPaymentOptions(e.target.value)
    formikRef.current.setFieldValue(
      'amount',
      e.target.value === '1'
        ? !fullPaymentDiscount
          ? paymentSummary.total_fee
          : fullPaymentDiscount?.type === 'percent'
          ? 100 - (parseInt(fullPaymentDiscount?.value) / 100) * paymentSummary.total_fee
          : paymentSummary.total_fee - parseInt(fullPaymentDiscount?.value)
        : paymentSummary.instalment_info[0].amount
    )
    formikRef.current.setFieldValue('nex_edu_discount_id', e.target.value === '2' ? null : !fullPaymentDiscount ? null : fullPaymentDiscount?.id)
  }
  const getEduPaymentSummary = async (id) => {
    try {
      setLoading(true)
      const res = await eduPaymentSummary(id)
      if (res.data.payment_settled === 0) {
        setPaymentSummary(res.data)
        setInitialValues({
          nex_edu_discount_id: res.data.applicable_discounts?.find((item) => item?.name === 'Full Payment')
            ? res.data.applicable_discounts?.find((item) => item?.name === 'Full Payment')?.id
            : null,
          nex_edu_current_enrolment_id: res.data.nex_edu_current_enrolment_id,
          amount: res.data?.first_payment === true ? undefined : res.data?.next_amount_due,
          agree_status: false,
        })
      } else {
        navigate('/upskill-payment')
      }
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    getEduPaymentSummary(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlePayNowButton = async (values, actions) => {
    const { nex_edu_discount_id, ...rest } = values
    let data
    if (!paymentSummary?.first_payment) {
      data = rest
    } else {
      data = values
    }
    try {
      setLoading(true)
      const res = await eduMakePayment(data)
      window.location.href = res.data
    } catch (e) {
      if (e.status === 401) {
      } else if (e.status === 422) {
        const errors = e.data.errors
        Object.keys(errors).forEach(function (key) {
          actions.setFieldError(key, errors[key][0])
        })
      } else if (e.status === 500) {
        setTextError(
          "Please add a valid phone number to your profile before proceeding with payment. If you're still seeing this error message after adding your phone number, please contact us immediately."
        )
        setShowError(true)
      }
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  const onCloseTnCModal = () => {
    setShowTnCModal(false)
  }

  const openTnc = (data) => {
    window.open(data)
  }

  return (
    <div className="min-h-screen bg-adx-gray px-4 py-12 ">
      <CustomHelmet title="Payment Summary | adnexio EDU" />

      {loading === true && (
        <>
          <div
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: '999999999',
            }}
          >
            <Loader type="Oval" color="#09b7b7" height={55} width={55} />
          </div>
          <div
            style={{
              position: 'fixed',
              zIndex: '5',
              width: '100vw',
              height: '100vh',
              background: 'black',
              opacity: '0.7',
            }}
          ></div>
        </>
      )}

      <Dialog open={showTnCModal} onClose={() => onCloseTnCModal()} maxWidth="sm">
        <div className="w-full p-4">
          <div className="mb-4 flex items-center justify-between">
            <p className="font-bold ">Term and Conditions</p>
            <IconButton onClick={() => onCloseTnCModal()} size="large">
              <CloseIcon />
            </IconButton>
          </div>
          <hr className="my-2" />
          <div className="max-h-96 overflow-y-auto"></div>
          <p className="text-justify text-sm lg:text-base">
            1. Student shall pay a course fee ("Course Fee") of Ringgit Malaysia Two Thousand and Five Hundred Only (RM2,500) if paid in one lump sum
            at the beginning of the course; or Ringgit Malaysia Three Thousand Only (RM3,000) in three monthly instalment of Ringgit Malaysia One
            Thousand (RM1,000) each.
          </p>
          <br />

          <p className="text-justify text-sm lg:text-base">
            2. Student agrees to pay the applicable Course Fee. Student shall consent to the receipt of an invoice or a statement of the Course Fee,
            which shall be sent to the Student in an electronic format, to the email address provided upon registration. The price of courses will be
            reflected in Ringgit Malaysia, unless otherwise stated. If the Student selects an installment plan, the Student shall agree to make
            payment on the payment due date(s) for the amount(s) due.
          </p>
          <br />

          <p className="text-justify text-sm lg:text-base">
            3. If the full payment or installment is not received by Adnexio, by the due date for payment (as agreed during registration and recorded
            in your invoice/statement of the Course Fee), then Adnexio may suspend Student's participation in the course. In such cases, the Student
            shall not be permitted to access the course. At Adnexio's discretion, the Student may be permitted to resume access to the course,
            provided the outstanding payment has been paid to Student before the Course end date. Student hereby acknowledge and consent to Adnexio
            contacting the Student in relation to payments due by you, by way of email, text message, telephone calls, or other means as determined by
            us.{' '}
          </p>
          <br />

          <p className="text-justify text-sm lg:text-base">
            4. If the Student fails to pay the Course Fee in accordance with the agreed payment terms, Student will not receive the final result for
            the course, and this may impact the Student's Course completion. The Student shall not be issued a certificate until all Course Fee have
            been paid in full and have completed the required Course work (daily lessons, assignments, weekly tests and final project).
          </p>
          <br />

          <p className="text-justify text-sm lg:text-base">5. All payments made to Adnexio for the Course are non-refundable.</p>
          <br />

          <p className="text-justify text-sm lg:text-base">
            The above terms and conditions for Course Fee have been specified in clause 4 in your adnexio SEC Enrolment contract
          </p>
          <br />
        </div>
      </Dialog>
      <div className="mx-auto max-w-3xl">
        <img src={AdnexioEduV2} className="mx-auto mb-12 h-10" alt="adnexio_edu" />
        <div className="rounded-xl bg-white">
          <div className="mx-auto py-4 ">
            <div className="mx-auto flex w-5/6 items-center py-4">
              <img src={paymentSummary.course_logo} className="mr-8 h-20 w-20 rounded-lg" alt="course_logo" />
              <p className="text-lg font-bold text-adx-darkGreen">{paymentSummary.course_title}</p>
            </div>
            <hr className="my-4" />
            <div className="mx-auto w-5/6 ">
              <div className="mb-4 flex items-center justify-between ">
                <p className="text-base font-semibold lg:text-xl">Payment #{paymentSummary.current_payment_number}</p>
              </div>
              <div className="mb-4 rounded-lg bg-adx-gray py-4">
                <div className="mx-auto w-11/12 ">
                  <div className="flex items-center rounded-lg bg-white px-8 py-4">
                    <img src={paymentSummary.course_logo} className="mr-8 h-12 w-12 rounded-lg" alt="course_logo" />
                    <p className="text-sm font-bold text-adx-darkGreen lg:text-base">{paymentSummary.course_title}</p>
                  </div>
                  <div>
                    <Table aria-label="simple table">
                      <TableBody>
                        <TableRow>
                          <TableCell sx={mui.tableCell} width="70%">
                            <p className="text-sm font-normal lg:text-base">{paymentSummary.course_title}</p>
                          </TableCell>
                          <TableCell sx={mui.tableCell} width="27.5%" align="right">
                            <p className="text-sm font-normal lg:text-base">
                              {`${paymentSummary?.currency_code} `}
                              {(paymentSummary?.first_payment === true && paymentOptions === null) || paymentOptions === '1'
                                ? paymentSummary.total_fee?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                : paymentSummary?.first_payment === true && paymentOptions === '2'
                                ? `${(paymentSummary.total_fee / paymentSummary.total_num_instalments)
                                    ?.toString()
                                    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} x ${paymentSummary.total_num_instalments}`
                                : paymentSummary?.next_amount_due?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </p>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ ...mui.tableCell, ...mui.tableCellNoBorderBottom }} width="70%">
                            <p className="text-sm lg:text-base">Amount to be paid</p>
                          </TableCell>
                          <TableCell sx={{ ...mui.tableCell, ...mui.tableCellNoBorderBottom }} width="25%" align="right">
                            <p className="text-sm lg:text-base">
                              {`${paymentSummary?.currency_code} `}
                              {paymentSummary?.first_payment && paymentOptions === '1'
                                ? !fullPaymentDiscount
                                  ? paymentSummary.total_fee?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                  : fullPaymentDiscount?.type === 'percent'
                                  ? (100 - (parseInt(fullPaymentDiscount?.value) / 100) * paymentSummary.total_fee)
                                      ?.toString()
                                      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                  : (paymentSummary.total_fee - parseInt(fullPaymentDiscount?.value))
                                      ?.toString()
                                      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                : (paymentSummary?.first_payment && paymentOptions === null) ||
                                  (paymentSummary?.first_payment && paymentOptions === '2')
                                ? paymentSummary.total_fee?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                : paymentSummary.next_amount_due?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </p>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                </div>
              </div>

              <Formik
                onSubmit={(values, actions) => handlePayNowButton(values, actions)}
                initialValues={initialValues}
                enableReinitialize
                innerRef={formikRef}
                validationSchema={validationSchema}
              >
                {({ values, errors, handleChange, handleSubmit, isValid, dirty }) => (
                  <Form>
                    <ErrorMessage name="nex_edu_current_enrolment_id" component="p" className="text-red-500" />
                    {paymentSummary.first_payment && (
                      <div>
                        {paymentSummary.allow_instalments ? (
                          <div className="mt-8 mb-4">
                            <p className="text-base font-semibold lg:text-xl">Please select payment options</p>
                            <p className="text-sm lg:text-base">You can pay for this course in full today or space out your payments. </p>
                          </div>
                        ) : (
                          <div className="mt-8 mb-4">
                            <p className="text-base font-semibold lg:text-xl">Select the option below to proceed with the payment.</p>
                          </div>
                        )}
                        <label htmlFor="one-off" className="cursor-pointer">
                          <div
                            className={` mb-4 flex items-center rounded-lg border-2 px-4  py-4 ${
                              paymentOptions === '1' && 'border-adx-darkGreen bg-adx-lightTonedGreen'
                            }`}
                          >
                            <Radio
                              id="one-off"
                              color="primary"
                              sx={mui.radioBtn}
                              value={1}
                              checked={paymentOptions === '1'}
                              onChange={(e) => handlePaymentOptionsChange(e)}
                            />
                            <p className="text-sm lg:text-base">
                              One-off ({`${paymentSummary?.currency_code} `}
                              {!fullPaymentDiscount
                                ? paymentSummary.total_fee?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                : fullPaymentDiscount?.type === 'percent'
                                ? (((100 - parseInt(fullPaymentDiscount?.value)) / 100) * paymentSummary?.total_fee)
                                    ?.toString()
                                    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                : (paymentSummary.total_fee - parseInt(fullPaymentDiscount?.value))
                                    ?.toString()
                                    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              )
                            </p>
                          </div>
                        </label>
                        {paymentSummary.allow_instalments && (
                          <label htmlFor="installment" className="cursor-pointer">
                            <div className={` mb-4  rounded-lg border-2 ${paymentOptions === '2' && 'border-adx-darkGreen bg-adx-lightTonedGreen'}`}>
                              <div className={` flex items-center px-4 py-4`}>
                                <Radio
                                  id="installment"
                                  color="primary"
                                  sx={mui.radioBtn}
                                  value={2}
                                  checked={paymentOptions === '2'}
                                  onChange={(e) => handlePaymentOptionsChange(e)}
                                />
                                <p className="text-sm lg:text-base">{paymentSummary?.instalment_info.length} instalment with respective due date :</p>
                              </div>

                              <hr className="mx-8 my-2" />

                              {paymentSummary?.instalment_info.map((item, index) => (
                                <div key={index} className="mb-4 ml-12 px-8 py-2">
                                  <p className="text-sm lg:text-base">
                                    Payment #{index + 1} {paymentSummary?.currency_code} {item.amount} by{' '}
                                    {index === 0 ? moment(item.due_date).format('Do MMM YYYY') : moment(item.due_date).format('Do MMM YYYY')}
                                    {'  '}
                                  </p>
                                </div>
                              ))}
                            </div>
                          </label>
                        )}
                      </div>
                    )}
                    <ErrorMessage name="amount" component="p" className="text-red-500" />

                    {paymentSummary?.tnc_important_points && (
                      <div className="my-5 h-[200px] overflow-auto border-2 border-adx-gray bg-white p-5">
                        <p className="whitespace-pre-line text-xs" style={{ lineHeight: '1.55rem' }}>
                          {paymentSummary?.tnc_important_points}
                        </p>
                      </div>
                    )}

                    {!(!paymentSummary?.first_payment && paymentSummary?.next_amount_due === null) && (
                      <div>
                        <label htmlFor="agree_status" className="flex items-center">
                          <Checkbox color="primary" id="agree_status" name="agree_status" onChange={handleChange} />
                          <p className="text-sm lg:text-base">
                            I have read and agree to the{' '}
                            <span onClick={() => openTnc(paymentSummary?.tnc_url)} className="cursor-pointer text-blue-500">
                              terms and conditions.
                            </span>
                          </p>
                        </label>
                        <ErrorMessage name="agree_status" component="p" className="text-red-500" />
                      </div>
                    )}
                    {showError && <p className="rounded-md border-2 border-red-500 bg-red-200 p-2 text-sm text-red-500 lg:text-base">{textError}</p>}
                    <div className="my-2">
                      <Button
                        variant="contained"
                        sx={mui.payNowBtn}
                        onClick={handleSubmit}
                        disabled={!(isValid && dirty) || (!paymentSummary?.first_payment && paymentSummary?.next_amount_due === null)}
                      >
                        {!(!paymentSummary?.first_payment && paymentSummary?.next_amount_due === null) ? 'Pay Now' : 'Fully Paid'}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>

              <div className="my-2 text-center">
                <Link to="/upskill-payment">
                  <Button variant="text" fullWidth sx={mui.returnToDashboardBtn}>
                    Return to dashboard
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentSummary
