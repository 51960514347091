import { downloadQuotation } from 'services/api/hrd'
import icon from '../assets/verifiedImg.png'
// import icon from '../assets/verifiedImg.svg'
import { useState } from 'react'

const HRDEligibleSuccess = ({ nex_edu_payment_schedule_id }) => {
  const [loading, setLoading] = useState(false)
  const fetchQuotation = async () => {
    try {
      setLoading(true)
      const res = await downloadQuotation(nex_edu_payment_schedule_id)
      const quotation = 'NEX-HRD-Quotation.pdf'
      const element = document.createElement('a')
      const file = new Blob([res.data], { type: 'application/pdf' })
      element.href = URL.createObjectURL(file)
      element.download = `${quotation}`
      document.body.appendChild(element)
      element.click()
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }
  return (
    <>
      <div className="mx-auto max-w-[90%] md:max-w-[80%]">
        <div className="flex items-center justify-center">
          <img alt="" src={icon} />
        </div>
        <h3 className="leading-[calc(24px * 1.5)] mt-[40px] mb-[16px] text-center text-[24px] font-medium">
          Your HRD Corp grant application is eligible
        </h3>
        <p className="mb-[16px] text-center text-adx-darkerGray">
          Congratulations! Your HRD grant application is eligible, and we're thrilled for you. To help you complete the application process, we've
          sent you an email with all the required documents.
        </p>
        <p className="text-center text-adx-darkerGray">
          You may download your{' '}
          {loading ? (
            <span className="text-adx-darkerGray">loading...</span>
          ) : (
            <span onClick={fetchQuotation} className="cursor-pointer text-adx-darkGreen underline">
              quotation here
            </span>
          )}
        </p>
      </div>
    </>
  )
}

export default HRDEligibleSuccess
