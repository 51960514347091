import { useState, useEffect, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import moment from 'moment'
import * as Scroll from 'react-scroll'

import Button from '@mui/material/Button'
import { Box, useMediaQuery } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

import HeaderEdu from 'modules/edu/components/Header/view/HeaderEdu'
import Testimonial from '../components/Testimonial'
import Footer from 'components/dumb/Footer'
import AdxCarousel from 'components/smart/Carousel/AdxCarousel'
import EDUAlertModal from 'modules/edu/components/AlertModal'
import CustomHelmet from 'components/smart/CustomHelmet'

import { getCourseList } from 'services/api/upskill'

import IconOne from '../assets/module_based.png'
import IconTwo from '../assets/trusted.png'
import IconThree from '../assets/graduate.png'
import IconFour from '../assets/money.png'
import BannerOne from '../assets/edubootcamp_banner_01.jpg'
import BannerTwo from '../assets/edubootcamp_banner_02.jpg'
import { IpContext } from 'contexts/IpContext'

const mui = {
  submitBtn: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    textTransform: 'none',
    fontWeight: '600',
    background: '#FCD94E',
    color: '#1B8379',
    borderRadius: '4px',
    boxShadow: 'none',
    padding: '7px 0px',
    '&:hover': {
      background: '#FCD94E',
      boxShadow: 'none',
    },
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
    '@media (max-width: 1020px)': {
      marginTop: '10px',
    },
  },
  viewBtn: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    textTransform: 'none',
    fontWeight: '600',
    background: '#FCD94E',
    color: '#1B8379',
    borderRadius: '4px',
    boxShadow: 'none',
    padding: '4px 23px',
    '&:hover': {
      background: '#FCD94E',
      boxShadow: 'none',
    },
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
  },
  root: {
    marginTop: '18px',
  },
  formField: {
    backgroundColor: '#FFFFFF',
    borderRadius: '6px',
    '& .MuiInputBase-root': {
      borderRadius: '6px',
    },
  },
}

const EDULandingPage = () => {
  const { ipCountry } = useContext(IpContext)
  const [mappedArray, setMappedArray] = useState([])
  const [loading, setLoading] = useState(true)
  const [open, setOpen] = useState(false)
  const [modalProp, setApplyModalProp] = useState({
    success: null,
    title: '',
    body: '',
    button: '',
  })
  var scroll = Scroll.animateScroll
  const navigate = useNavigate()
  const isLarge = useMediaQuery((theme) => theme.breakpoints.up('lg'))

  const banners = [{ banner_image: BannerOne }, { banner_image: BannerTwo }]

  const filterArrayFunc = (responseArray) => {
    if (responseArray.length > 4) {
      let emptyArray = []
      for (let i = 0; i < responseArray.length; i = i + 1) {
        if (i >= responseArray.length - 4) {
          emptyArray.push(responseArray[i])
        }
      }
      setMappedArray(emptyArray)
      setLoading(false)
    } else {
      setMappedArray(responseArray)
      setLoading(false)
    }
  }

  const onCloseAlertModal = () => {
    setOpen(false)
    setApplyModalProp({
      success: null,
      title: '',
      body: '',
      button: '',
    })
  }

  const loadCourseList = async (country) => {
    setLoading(true)
    await getCourseList(null, country)
      .then(async (response) => {
        if (response.data.last_page === response.data.current_page) {
          filterArrayFunc(response.data.data)
        } else {
          await getCourseList(response.data.last_page)
            .then(async (second) => {
              if (second.data.data.length >= 4) {
                filterArrayFunc(second.data.data)
              } else {
                await getCourseList(response.data.last_page - 1)
                  .then((third) => {
                    let arrayFunction = [...third.data.data, ...second.data.data]
                    filterArrayFunc(arrayFunction)
                  })
                  .catch((thirdError) => {
                    console.error(thirdError)
                    setMappedArray([])
                    setLoading(false)
                  })
              }
            })
            .catch((secondError) => {
              console.error(secondError)
              setMappedArray([])
              setLoading(false)
            })
        }
      })
      .catch((error) => {
        console.error(error)
        setMappedArray([])
        setLoading(false)
      })
  }

  useEffect(() => {
    loadCourseList(ipCountry)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ipCountry])

  useEffect(() => {
    // window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <CustomHelmet title="adnexio | EDU" url="https://adnexio.jobs" />
      <HeaderEdu page="edu" />
      <div className="bg-[#F7F9FA]">
        <AdxCarousel infinite timer={8000} refId={'banner'}>
          {banners.map((item, index) => {
            return (
              <Box
                onClick={() => navigate('/courses')}
                sx={{ cursor: 'pointer', objectFit: 'cover' }}
                key={index}
                component="img"
                src={item.banner_image}
              />
            )
          })}
        </AdxCarousel>
      </div>

      {/* 1st row */}
      <Box className="flex justify-center bg-[#F7F9FA] px-4 py-20">
        <Box className="max-w-2xl lg:max-w-7xl">
          <h1 className="text-center text-3xl font-medium text-adx-lightBlack lg:text-4xl">
            Why <span className="text-[#09A3A9]">adnexioEDU?</span>
          </h1>

          <h1
            style={{
              marginTop: 20,
              textAlign: 'center',
            }}
            className="text-sm text-[#8A8A8A] lg:max-w-3xl"
          >
            Whether you're interested in learning new skills or have an eye on a complete career change, we've got the course for you. Our programmes
            offer solutions-based learning to prepare you for the workplace. Gain the extra edge you need to stand out amongst the crowd.
          </h1>
        </Box>
      </Box>
      <Box className="flex justify-center px-2 py-16 lg:py-20">
        <Box sx={{ display: 'flex', flexDirection: isLarge ? 'row' : 'column', rowGap: 4 }} className="max-w-lg lg:max-w-7xl">
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box className="h-24 w-24 self-center rounded-full">
              <img src={IconOne} alt="module_based" />
            </Box>
            <h1 className="mt-2 text-center text-lg font-semibold">Skills First Learning</h1>
            <p className="font-regular mt-1.5 px-3 text-center text-sm text-adx-textGray">
              Build your fundamentals with structured syllabuses, based on the latest industry requirements.
            </p>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div className="h-24 w-24 self-center rounded-full">
              <img src={IconTwo} alt="trusted" />
            </div>
            <div className="mt-2 text-center text-lg font-semibold">Trusted Masterclasses</div>
            <div className="font-regular mt-1.5 px-3 text-center text-sm text-adx-textGray">
              Get certifications and opportunities to showcase your skills to employers.
            </div>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div className="h-24 w-24 self-center rounded-full">
              <img src={IconThree} alt="graduate" />
            </div>
            <div className="mt-2 text-center text-lg font-semibold">Graduate into a Job</div>
            <div className="font-regular mt-1.5 px-3 text-center text-sm text-adx-textGray">
              Kickstart your career by completing our programmes. Graduate into the job of your dreams.
            </div>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div className="h-24 w-24 self-center rounded-full">
              <img src={IconFour} alt="affordable" />
            </div>
            <div className="mt-2 text-center text-lg font-semibold">Value for Money</div>
            <div className="font-regular mt-1.5 px-3 text-center text-sm text-adx-textGray">
              Fast track learning and upskilling with industry expert trainers.
            </div>
          </Box>
        </Box>
      </Box>

      {/* The comment section below is to be used after product owners have given the video and testimonial by SET people. */}
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: isLarge ? 'row' : 'column',
        }}
      >
        <Box sx={{ width: '100%', backgroundColor: '#F7F9FA', p: 4 }}>
          <Box sx={{ my: 4 }}>
            <h1 className="text-center text-lg font-medium text-adx-lightBlack lg:mr-2 lg:text-4xl">What are our students saying</h1>
          </Box>
          <Box className="flex justify-center">
            <Box className="max-w-7xl">
              <Testimonial />
            </Box>
          </Box>
        </Box>
      </Box>

      {/* 2nd row */}

      {loading && (
        <div className="mx-auto w-max p-32">
          <CircularProgress sx={{ color: '#09A3A9' }} />
        </div>
      )}

      {!loading && mappedArray.length !== 0 && (
        <div className="flex justify-center">
          <div className="max-w-7xl px-8 py-14">
            <div className="ml-7 text-lg font-medium text-adx-lightBlack lg:text-3xl">Courses</div>
            <div className="flex justify-end">
              <Link to="/courses">
                <Button onClick={() => scroll.scrollToTop()}>View all</Button>
              </Link>
            </div>
            <div className="mt-3.5 grid grid-cols-1 lg:grid-cols-4">
              {mappedArray.map((mapProps) => {
                return (
                  <div className="mx-3.5 mb-4 flex flex-col justify-between rounded-lg bg-adx-gray py-5 px-6" key={mapProps.id}>
                    <div className={`text-sm font-semibold ${mapProps.course_type === 'COURSE' ? 'text-edu-primary' : 'text-adx-darkGreen'}`}>
                      {mapProps.course_type === 'COURSE' ? 'COURSE' : 'BOOTCAMP'}
                    </div>
                    <div className="text-lg font-semibold text-adx-lightBlack">{mapProps.course_title}</div>
                    <div className="mt-2 text-sm text-adx-textGray line-clamp-3">{mapProps.course_description}</div>
                    <div className="text-adx-black mt-4 text-sm font-semibold lg:text-sm">
                      {mapProps.course_type === 'COURSE'
                        ? 'Flexible learning'
                        : `Intake 
                        ${
                          mapProps.nex_edu_course_intakes.length !== 0
                            ? moment(mapProps.nex_edu_course_intakes[0].start_date).format('DD MMMM YYYY')
                            : 'To Be Confirmed'
                        }`}
                    </div>
                    <div className="mt-4 self-end">
                      {mapProps?.course_type === 'COURSE' ? (
                        <Button
                          variant="contained"
                          sx={mui.viewBtn}
                          onClick={() => window.open(`${process.env.REACT_APP_EDU_URL}/course/${mapProps?.id}`)}
                        >
                          View
                        </Button>
                      ) : (
                        <Link to={`/courses/${mapProps.id}`}>
                          <Button variant="contained" sx={mui.viewBtn} onClick={() => scroll.scrollToTop()}>
                            View
                          </Button>
                        </Link>
                      )}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )}
      <EDUAlertModal onClose={onCloseAlertModal} onClickButton={onCloseAlertModal} open={open} modalProp={modalProp} />

      <Footer />
    </div>
  )
}
export default EDULandingPage
