import React, { Suspense, lazy } from 'react'
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom'
import Loader from 'react-loader-spinner'
import { ThemeProvider } from '@mui/material/styles'
import { muiTheme } from 'muiTheme'
import CustomHelmet from 'components/smart/CustomHelmet'
import PrivateRoute from 'components/smart/PrivateRoute'
import Provider from './Provider'

import EDULandingPage from 'modules/edu/pages/upskilling/view/EDULandingPage'
import EDUSignIn from 'modules/edu/pages/sign_in/views/EDUSignIn'
import EDUSignUp from 'modules/edu/pages/registration/views/EDUSignUp'
import EDUContactUs from 'modules/edu/pages/setting/view/EDUContactUs'
import CourseAds from 'pages/courses_ads/views/CourseAds'
import CourseAdsDetails from 'pages/courses_ads_details/views/CourseAdsDetails'
import EduRedirectSocialAuth from 'components/smart/EduRedirectSocialAuth'
import PrivacyPolicy from 'pages/setting/view/PrivacyPolicy'
import TermsConditions from 'pages/setting/view/TermsConditions'
import Faq from 'pages/setting/view/Faq'
import Nex from 'pages/setting/view/Nex'

import EDUClassesDetails from 'modules/edu/pages/classes_details/view/EDUClassesDetails'
import MyProgress from 'pages/my_progress/view/MyProgress'
import ForgotPassword from 'pages/forgot_password/view/ForgotPassword'
import ResetPassword from 'pages/forgot_password/view/ResetPassword'
import CourseNotFound from 'pages/not_found/view/CourseNotFound'
import CourseApplicationDetails from 'pages/courses_application_details/views/CourseApplicationDetails'
import HRDIntro from 'pages/hrd/view/HRDIntro'
import HRDFormPage from 'pages/hrd/view/HRDFormPage'
import HRDStatus from 'pages/hrd/view/HRDStatus'
import HRDPaymentSummary from 'pages/hrd/view/HRDPaymentSummary'
import PaymentOption from 'pages/hrd/view/PaymentOption'
import Payment from 'pages/payment/view/Payment'
import PaymentSummary from 'pages/payment_summary/view/PaymentSummary'
import PaymentStatus from 'pages/payment_status/view/PaymentStatus'
import ZoomPlayback from 'pages/zoom_playback/view/ZoomPlayback'
import NotFound from 'pages/not_found/view/NotFound'

const EDUProfile = lazy(() => import('modules/edu/pages/profile/view/EDUProfile'))
const EDUMyClasses = lazy(() => import('modules/edu/pages/my_classes/view/EDUMyClasses'))

const Layout = ({ children }) => (
  <>
    <CustomHelmet />
    <div className="font-poppins">
      <ThemeProvider theme={muiTheme}>
        <Provider>{children}</Provider>
      </ThemeProvider>
    </div>
  </>
)

function App() {
  return (
    <Layout>
      <BrowserRouter>
        <Suspense fallback={<FallbackUI />}>
          <Routes>
            <Route path={ROUTES.HOME} element={<Outlet />}>
              {/* Public Routes */}
              <Route index element={<EDULandingPage />} /> {/* Home page */}
              <Route path={ROUTES.SIGNIN} element={<EDUSignIn />} /> {/* Sign in page */}
              <Route path={ROUTES.SIGNUP} element={<EDUSignUp />} /> {/* Sign up page */}
              <Route path={ROUTES.CONTACT_US} element={<EDUContactUs />} /> {/* Contact us page */}
              <Route path={ROUTES.COURSES} element={<CourseAds />} /> {/* Course listings */}
              <Route path={`${ROUTES.COURSES}/:id`} element={<CourseAdsDetails />} /> {/* Individual course details */}
              <Route path={ROUTES.COURSE_NOT_FOUND} element={<CourseNotFound />} /> {/* Course not found page */}
              {/* Social auth redirect routes */}
              <Route path={`${ROUTES.REDIRECT}/:status/:value`} element={<EduRedirectSocialAuth />} />
              <Route path={`${ROUTES.REDIRECT}/:status/:value/:expired_at`} element={<EduRedirectSocialAuth />} />
              <Route path={ROUTES.PRIVACY_POLICY} element={<PrivacyPolicy />} /> {/* Privacy policy */}
              <Route path={ROUTES.TERMS_CONDITIONS} element={<TermsConditions />} /> {/* Terms and conditions */}
              <Route path={ROUTES.FAQ} element={<Faq />} /> {/* FAQ */}
              <Route path={ROUTES.NEX} element={<Nex />} /> {/* Nex page */}
              <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} /> {/* Forgot password */}
              <Route path={`${ROUTES.RESET_PASSWORD}/:token`} element={<ResetPassword />} /> {/* Reset password */}
              {/* Private Routes */}
              <Route element={<PrivateRoute redirectTo={ROUTES.SIGNIN} />}>
                {/* User profile and classes */}
                <Route path={ROUTES.PROFILE} element={<EDUProfile />} /> {/* User profile */}
                <Route path={ROUTES.MY_CLASSES} element={<EDUMyClasses />} /> {/* User's classes */}
                <Route path={`${ROUTES.MY_CLASSES}/:id`} element={<EDUClassesDetails />} /> {/* Individual class details */}
                <Route path={`${ROUTES.MY_PROGRESS}/:id`} element={<MyProgress />} /> {/* User's progress */}
                <Route path={`${ROUTES.PLAYBACK}/:id/:playbackID`} element={<ZoomPlayback />} /> {/* Zoom playback */}
                <Route path={`${ROUTES.COURSE_APPLICATION}/:id`} element={<CourseApplicationDetails />} /> {/* Course application details */}
                {/* HRD routes */}
                <Route path={ROUTES.HRD_INTRO} element={<HRDIntro />} /> {/* HRD introduction */}
                <Route path={ROUTES.HRD_FORM} element={<HRDFormPage />} /> {/* HRD form */}
                <Route path={ROUTES.HRD_STATUS} element={<HRDStatus />} /> {/* HRD status */}
                <Route path={`${ROUTES.HRD_PAYMENT_SUMMARY}/:id`} element={<HRDPaymentSummary />} /> {/* HRD payment summary */}
                {/* Payment routes */}
                <Route path={`${ROUTES.PAYMENT_OPTIONS}/:id`} element={<PaymentOption />} /> {/* Payment options */}
                <Route path={ROUTES.UPSKILL_PAYMENT} element={<Payment />} /> {/* Upskill payment */}
                <Route path={`${ROUTES.PAYMENT_SUMMARY}/:id`} element={<PaymentSummary />} /> {/* Payment summary */}
                <Route path={`${ROUTES.PAYMENT_STATUS}/:status/:orderId`} element={<PaymentStatus />} /> {/* Payment status */}
              </Route>
            </Route>
            {/* Catch-all route for 404 Not Found */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </Layout>
  )
}

export default App

const ROUTES = {
  HOME: '/',
  SIGNIN: '/signin',
  SIGNUP: '/signup',
  CONTACT_US: '/contact-us',
  COURSES: '/courses',
  REDIRECT: '/redirect',
  PRIVACY_POLICY: '/privacy-policy',
  TERMS_CONDITIONS: '/terms-conditions',
  FAQ: '/faq',
  NEX: '/nex',
  PROFILE: '/profile',
  MY_CLASSES: '/my-classes',
  MY_PROGRESS: '/my-progress',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset_password',
  COURSE_NOT_FOUND: '/course-not-found',
  COURSE_APPLICATION: '/course-application',
  HRD_INTRO: '/hrd/introduction',
  HRD_FORM: '/hrd/form',
  HRD_STATUS: '/hrd/status',
  HRD_PAYMENT_SUMMARY: '/hrd/payment-summary',
  PAYMENT_OPTIONS: '/payment-options',
  UPSKILL_PAYMENT: '/upskill-payment',
  PAYMENT_SUMMARY: '/payment-summary',
  PAYMENT_STATUS: '/payment-status',
  PLAYBACK: '/playback',
}

const FallbackUI = () => {
  return (
    <div className="flex h-screen items-center justify-center">
      <Loader type="Oval" color="#09b7b7" height={55} width={55} />
    </div>
  )
}
