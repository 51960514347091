import { Button, Radio, Table, TableBody, TableCell, TableRow } from '@mui/material'
import AdnexioEduV2 from 'assets/logos/adnexio-logo-edu-v2-aug.png'
import CustomHelmet from 'components/smart/CustomHelmet'
import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import Loader from 'react-loader-spinner'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import hrdLogo from '../assets/hrd-logo-1.png'
import { CheckCircle, Info } from '@mui/icons-material'
import { ReactComponent as Voucher } from '../assets/voucherIcon.svg'
import { getPaymentSummaryHrdf } from 'services/api/hrd'

const PaymentOption = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({})
  const navigate = useNavigate()
  const { id: nex_edu_payment_schedule_id } = useParams()
  const { state } = useLocation()
  const handleChoices = (values) => {
    if (values?.option === '1') {
      navigate(`/hrd/payment-summary/${nex_edu_payment_schedule_id}`, { state: nex_edu_payment_schedule_id })
    } else {
      navigate(`/payment-summary/${nex_edu_payment_schedule_id}`)
    }
  }
  const handleReturnDashboard = () => {
    navigate('/courses')
  }

  const handleCheckStatus = () => {
    if (!data?.hrdf_application_status_id) {
      navigate('/hrd/introduction', { state: nex_edu_payment_schedule_id })
    } else if ([1, 2, 3, 5].includes(data?.hrdf_application_status_id)) {
      navigate('/hrd/status', {
        state: {
          nex_edu_payment_schedule_id,
          data: null,
        },
      })
    } else {
      return null
    }
  }
  const fetchApi = async () => {
    setLoading(true)
    try {
      const res = await getPaymentSummaryHrdf(nex_edu_payment_schedule_id)
      setData(res)
    } catch (error) {
      setLoading(false)
      console.error(error)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    fetchApi()
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nex_edu_payment_schedule_id])
  if (!state) {
    return <Navigate to={`/payment-summary/${nex_edu_payment_schedule_id}`} />
  }
  return (
    <div className="min-h-screen bg-adx-gray px-4 py-12 ">
      <CustomHelmet title="Payment Summary | adnexio EDU" /> {/* to change the head meta tag */}
      {loading && (
        <>
          <div
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: '999999999',
            }}
          >
            <Loader type="Oval" color="#09b7b7" height={55} width={55} />
          </div>
          <div
            style={{
              position: 'fixed',
              zIndex: '5',
              width: '100vw',
              height: '100vh',
              background: 'black',
              opacity: '0.7',
            }}
          ></div>
        </>
      )}
      <div className="mx-auto max-w-3xl">
        <img src={AdnexioEduV2} className="mx-auto mb-12 h-10" alt="adnexio_edu" />
        <div className="rounded-xl bg-white">
          <div className=" mx-auto py-4 ">
            <div className="mx-auto flex w-5/6 items-center py-4">
              <img src={data?.course_logo} className="mr-8 h-20 w-20 rounded-lg" alt="course logo" />
              <p className="text-lg font-bold text-adx-darkGreen">{data?.course_title}</p>
            </div>
            <hr className="my-4" />
            <div className="mx-auto w-5/6 ">
              <div className="mb-4 flex items-center justify-between ">
                <p className="text-base font-semibold lg:text-xl">Summary</p>
              </div>
              <div className="mb-4 rounded-lg bg-adx-gray py-4">
                <div className=" mx-auto w-11/12">
                  <div className="flex items-center rounded-lg bg-white py-4 px-8">
                    <img src={data?.course_logo} className="mr-8 h-12 w-12 rounded-lg" alt="course logo" />
                    <p className="text-sm font-bold text-adx-darkGreen lg:text-base">{data?.course_title}</p>
                  </div>
                  <div>
                    <Table aria-label="simple table">
                      <TableBody>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: '30px 0px',
                              fontWeight: 'bold',
                            }}
                            width="70%"
                          >
                            <p className="text-sm font-normal lg:text-base">{data?.course_title}</p>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: '30px 0px',
                              fontWeight: 'bold',
                            }}
                            width="27.5%"
                            align="right"
                          >
                            <p className="text-sm font-normal lg:text-base">
                              {' '}
                              RM {data?.total_fee?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </p>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: '30px 0px',
                              fontWeight: 'bold',
                              borderBottom: 'none',
                            }}
                            width="70%"
                          >
                            <p className="text-sm lg:text-base">Total</p>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: '30px 0px',
                              fontWeight: 'bold',
                              borderBottom: 'none',
                            }}
                            width="25%"
                            align="right"
                          >
                            <p className="text-sm lg:text-base">RM {data?.total_fee?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</p>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-center space-y-4 rounded-[4.5px] bg-adx-gray p-[20px] text-center md:flex-row md:space-y-0 md:space-x-[14px] md:text-left">
                <div className="relative">
                  <img alt="hrd logo" src={hrdLogo} className="w-[100px]" />
                  <CheckCircle
                    color="success"
                    sx={{ position: 'absolute', top: -2, right: -4, width: '18px', height: '18px', backgroundColor: 'white', borderRadius: '100%' }}
                  />
                </div>
                <p className="text-sm lg:text-base">
                  This course is eligible for HRD Corp Claimable Course. Use HRD Corp incentive to receive a comprehensive reimbursement of your
                  training costs.
                </p>
              </div>

              <Formik
                onSubmit={handleChoices}
                initialValues={{
                  option: null,
                }}
                enableReinitialize
              >
                {({ values, handleChange, handleSubmit, dirty }) => (
                  <Form>
                    <div>
                      <div className="mb-4 mt-8">
                        <p className="text-base font-semibold lg:text-xl">Select any options to continue</p>
                      </div>
                      <label htmlFor="hrd-option" className="cursor-pointer">
                        <div
                          className={` mb-4 rounded-lg border-2 px-4  py-4 ${
                            values?.option === '1' && 'border-adx-darkGreen bg-adx-lightTonedGreen'
                          }`}
                        >
                          <div className="flex items-center justify-between">
                            <div className="flex items-center">
                              <Radio
                                id="hrd-option"
                                color="primary"
                                name="option"
                                disabled={!data?.hrdf_application_status_id || data?.hrdf_application_status_id !== 4}
                                sx={{
                                  marginRight: '16px',
                                }}
                                value={1}
                                checked={values?.option === '1'}
                                onChange={handleChange}
                              />
                              <p
                                className={`text-sm lg:text-base ${
                                  !data?.hrdf_application_status_id || data?.hrdf_application_status_id !== 4
                                    ? 'text-adx-darkerGray'
                                    : 'text-adx-darkText'
                                }`}
                              >
                                {data?.hrdf_application_status_id && data?.hrdf_application_status_id === 4
                                  ? 'Use HRD Corp incentive'
                                  : 'Apply HRD Corp Claimable Course incentive'}
                              </p>
                            </div>
                            <Voucher />
                          </div>
                          <div
                            className={`${
                              data?.hrdf_application_status_id && data?.hrdf_application_status_id === 4 ? 'bg-[#E0FCE8]' : 'bg-[#FBF5E2]'
                            } mt-[20px] flex items-center justify-between rounded-[4.5px] p-[16px]`}
                          >
                            <div className="flex items-center space-x-4">
                              {data?.hrdf_application_status_id && data?.hrdf_application_status_id === 4 ? (
                                <CheckCircle color="success" />
                              ) : (
                                <Info color="warning" />
                              )}

                              <p className="text-sm lg:text-base">
                                {data?.hrdf_application_status_id && data?.hrdf_application_status_id === 4
                                  ? 'Your grant appication is approved'
                                  : 'You are not eligible yet'}
                              </p>
                            </div>
                            {data && data?.hrdf_application_status_id !== 4 && (
                              <span onClick={handleCheckStatus} className="cursor-pointer text-[#E2B93B] underline">
                                Apply for eligibility
                              </span>
                            )}
                          </div>
                        </div>
                      </label>
                      <label htmlFor="normal-option" className="cursor-pointer">
                        <div className={` mb-4  rounded-lg border-2 ${values?.option === '2' && 'border-adx-darkGreen bg-adx-lightTonedGreen'}`}>
                          <div className={` flex items-center px-4 py-4`}>
                            <Radio
                              id="normal-option"
                              color="primary"
                              name="option"
                              sx={{
                                marginRight: '16px',
                              }}
                              value={2}
                              checked={values?.option === '2'}
                              onChange={handleChange}
                            />
                            <p className="text-sm lg:text-base">Continue without HRD Corp incentive</p>
                          </div>
                        </div>
                      </label>
                    </div>

                    <div className="my-2">
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: '#FCD94E',
                          width: '100%',
                          textTransform: 'capitalize',
                          color: '#1B8379',
                          boxShadow: 'none',
                          minHeight: '45px',
                          '&:hover': {
                            color: '#FCD94E',
                          },
                        }}
                        onClick={handleSubmit}
                        disabled={!dirty}
                      >
                        Continue
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>

              <div className="my-2 text-center">
                <Button
                  fullWidth
                  onClick={handleReturnDashboard}
                  variant="text"
                  sx={{
                    color: '#3294F6',
                    minHeight: '45px',
                  }}
                >
                  Return to dashboard
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentOption
