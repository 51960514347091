import { useEffect, useState } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'

import CancelIcon from '@mui/icons-material/Cancel'
import IconButton from '@mui/material/IconButton'

import { getCourseSessions, getCurrentEnrolment } from 'services/api/current_enrolment'

const mui = {
  iconStyle: {
    color: '#FCD94E',
    width: 35,
    height: 35,
    '&:hover': {
      color: '#FCCE4E',
    },
  },
}

const ZoomPlayback = () => {
  const [sessionData, setSessionData] = useState(null)

  const navigate = useNavigate()
  const param = useParams()
  const location = useLocation()

  let historyData = location.state

  const checkValidation = async () => {
    try {
      let detailedResponse = await getCourseSessions(param.id)
      let mainResponse = await getCurrentEnrolment()

      let detailedObject = detailedResponse.data.find((element) => {
        return element.id === parseInt(param.playbackID)
      })
      let mainObject = mainResponse.data.find((element) => {
        return element.id === parseInt(param.id)
      })

      if (
        detailedObject &&
        mainObject &&
        !detailedObject?.isJoinable &&
        detailedObject?.wasCompleted &&
        detailedObject?.recording_url
      ) {
        setSessionData(detailedObject)
      } else {
        window.location.href = '/course-not-found'
      }
    } catch (error) {
      window.location.href = '/course-not-found'
    }
  }

  useEffect(() => {
    if (historyData) {
      setSessionData(historyData)
    } else {
      checkValidation()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlePress = () => {
    navigate(`/my-classes/${param.id}`)
  }

  return (
    <div className="flex min-h-[100dvh] items-center justify-center bg-black">
      <div className="max-w-6xl px-4 py-10">
        <div className="mb-6 text-xl font-medium text-adx-yellow md:text-2xl">
          {sessionData?.session_title}
        </div>
        <video
          className="w-screen rounded-lg border border-gray-900 shadow"
          controls
          key={param.playbackID}
          controlsList="nodownload"
        >
          {sessionData && <source src={sessionData?.recording_url} />}
        </video>
      </div>

      <div className="absolute top-3 right-3">
        <IconButton onClick={handlePress} size="medium">
          <CancelIcon sx={mui.iconStyle} />
        </IconButton>
      </div>
    </div>
  )
}

export default ZoomPlayback
