import React from 'react'
import AuthProvider from 'contexts/AuthContext'
import EmployerCardContextProvider from 'contexts/EmployerCardContext'
import IpContextProvider from 'contexts/IpContext'
import ProfileContextProvider from 'contexts/ProfileContext'
import NotificationContextProvider from 'contexts/NotificationContext'
import CourseCardContextProvider from 'contexts/CourseCardContext'

const Provider = ({ children }) => (
  <AuthProvider>
    <CourseCardContextProvider>
      <EmployerCardContextProvider>
        <ProfileContextProvider>
          <IpContextProvider>
            <NotificationContextProvider>{children}</NotificationContextProvider>
          </IpContextProvider>
        </ProfileContextProvider>
      </EmployerCardContextProvider>
    </CourseCardContextProvider>
  </AuthProvider>
)

export default Provider
