import React from 'react'
import { ReactComponent as AdnexioJobs } from '../../../../../assets/logos/adnexio-jobs.svg'
import { ReactComponent as AdnexioEdu } from '../../../../../assets/logos/adnexio-edu.svg'
import { ReactComponent as AdnexioHr } from '../../../../../assets/logos/adnexio-hr.svg'
import { ReactComponent as AdnexioGigs } from '../../../../../assets/logos/adnexio-gigs.svg'

const products = [
  {
    title: 'Adnexio Jobs',
    link: '/jobs',
    logo: <AdnexioJobs />,
    description: 'Find your dream job with latest trend and demand',
  },
  {
    title: 'Adnexio Edu',
    link: '/',
    logo: <AdnexioEdu />,
    description: 'Learn courses tailored to the skills you need',
  },
  {
    title: 'Adnexio Hr',
    link: 'https://hr.adnexio.jobs',
    logo: <AdnexioHr />,
    description: 'Manage your HR tasks all in one place',
  },
  {
    title: 'Adnexio Gig',
    link: '/gigs',
    logo: <AdnexioGigs />,
    description: 'Earn extra money with flexible jobs',
  },
]

const ProductPanel = () => {
  return (
    <div className="flex flex-col bg-white px-6 py-8">
      {products.map((item, index) => (
        <a
          key={index}
          href={item.link}
          className={`
                        flex flex-col gap-3
                        ${index === 0 ? '' : 'border-t-[1px] border-adx-addBackground pt-6'}
                        ${index === products.length - 1 ? '' : 'pb-6'}
                    `}
        >
          <div>{item.logo}</div>
          <p className="text-sm font-normal text-adx-darkerGray">{item.description}</p>
        </a>
      ))}
    </div>
  )
}

export default ProductPanel
