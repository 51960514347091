import { useState, useEffect, useContext, useRef } from 'react'
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom'
import Loader from 'react-loader-spinner'
import moment from 'moment'

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Grow from '@mui/material/Grow'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import ShareIcon from '@mui/icons-material/Share'

import CustomHelmet from 'components/smart/CustomHelmet'
import ShareSocialMedia from 'components/smart/ShareSocialMedia'
import EDUAlertModal from 'modules/edu/components/AlertModal'
import HeaderEdu from 'modules/edu/components/Header/view/HeaderEdu'
import { authContext } from 'contexts/AuthContext'
import { courseAdvertisements } from 'services/api/course_advertisment'
import { selfAppliedCourse, selfWithdrawApplication } from 'services/api/course_application'

import apple_store_badges from '../assets/app-store-badge.png'
import google_play_badges from '../assets/google-play-badge.png'
import Fairuz from 'assets/images/fairuz.jpg'
import JunYang from 'assets/images/junyang.jpg'
import StatusChipApplication from 'pages/courses_ads/components/StatusChipApplication'
import { Box, ButtonBase } from '@mui/material'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import hrdLogo from '../../hrd/assets/hrd-logo-1.png'
import { getHrdfStatus } from 'services/api/hrd'
import { format } from 'date-fns'
import { IpContext } from 'contexts/IpContext'

const mui = {
  btn1: {
    height: 40,
    width: '100%',
    boxShadow: 'none',
    fontSize: '12px',
  },
  btn2: {
    textTransform: 'capitalize',
    height: 40,
    width: '100%',
    boxShadow: 'none',
    fontSize: '12px',
  },
  btnAdnexioGigMobile: {
    background: '#FCD94E',
    color: '#1B8379',
    textTransform: 'capitalize',
    height: 40,
    width: '100%',
    boxShadow: 'none',
    fontSize: '12px',
    '&:hover': {
      background: '#FCD94E',
      boxShadow: 'none',
    },
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
  },
  btnContained: {
    textTransform: 'capitalize',
    padding: '8px 18px',
    boxShadow: 'none',
    fontSize: '12px',
  },
  btnAdnexioGigWeb: {
    background: '#FCD94E',
    color: '#1B8379',
    textTransform: 'capitalize',
    padding: '8px 18px',
    boxShadow: 'none',
    fontSize: '12px',
    '&:hover': {
      background: '#FCD94E',
      boxShadow: 'none',
    },
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
  },
  btnShareDesktop: {
    color: '#E2E2E2',
    backgroundColor: '#1B8379',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    height: 40,
    width: '100%',
    '&:hover': {
      backgroundColor: '#1B8379',
    },
    '&:focus': {
      outline: 'none',
      color: 'none',
      backgroundColor: 'none',
    },
  },
  btnCourseStructure: {
    width: '100%',
    fontSize: 16,
  },
  accordionBox: {
    border: 'none',
    boxShadow: 'none',
    '&.MuiAccordion-root:before': {
      backgroundColor: 'white',
    },
  },
  accordionTitle: {
    border: 'solid #D9D9D9 1px',
    marginTop: 0,
    borderRadius: '4px',
  },
  accordionDescription: {
    backgroundColor: '#F5F5F5',
  },
  disableAccordion: {
    '&.Mui-disabled': {
      backgroundColor: 'white',
      opacity: 1,
    },
  },
  disableAccordionSummary: {
    opacity: '1 !important',
  },
}

const CourseAdsDetails = () => {
  const navigate = useNavigate()
  const param = useParams()
  const { auth } = useContext(authContext)
  const { ipCountry, ipLoading } = useContext(IpContext)
  const { isAuth: isLoggedIn, profileComplete } = auth

  const [courseAdvertisement, setCourseAdvertisement] = useState([])
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [copied, setCopied] = useState(false)
  const [preview, setPreview] = useState(false)
  const location = useLocation()
  const [openApplyModal, setOpenApplyModal] = useState(false)
  const [hrdfStatus, setHRDFStatus] = useState({})
  const [modalProp, setApplyModalProp] = useState({
    success: null,
    title: '',
    body: '',
    button: '',
  })

  const [expandedPanel, setExpandedPanel] = useState(false)
  const [openPopper, setOpenPopper] = useState(false)

  const anchorRefPopper = useRef(null)

  const handleDismissPopperModal = (event) => {
    if (anchorRefPopper.current && anchorRefPopper.current.contains(event.target)) {
      return
    }
    setOpenPopper(false)
  }

  // Get image file extension
  let re = /(?:\.([^.]+))?$/

  const handleApplyModalButton = () => {
    if (modalProp.button === 'Close') {
      navigate('/courses')
    } else if (modalProp.button === 'Go to Profile') {
      navigate('/profile')
    }
  }

  useEffect(() => {
    if (location.pathname.includes('preview')) {
      setPreview(true)
    }
  }, [location.pathname])

  useEffect(() => {
    getCourseAdvertisements(param.id)
  }, [param.id, courseAdvertisement.applied])

  const getCourseAdvertisements = async (id) => {
    try {
      const response = await courseAdvertisements('get', null, id)
      setCourseAdvertisement(response.data)
    } catch (e) {
      if (e.status === 404) {
        window.location.href = '/course-not-found'
      }
      console.error(e)
    }
  }

  const appDownload = (app) => {
    if (app === 'apple') {
      window.open('https://apps.apple.com/my/app/adnexio-jobs/id1500510440', '_blanks')
    } else if (app === 'android') {
      window.open('https://play.google.com/store/apps/details?id=org.adnexio.jobs', '_blanks')
    }
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setCopied(false)
  }

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : false)
  }

  // Remove Waitlist API Call
  const handleRemoveWaitlist = async (valueID) => {
    try {
      await selfWithdrawApplication(valueID)
      await getCourseAdvertisements(param.id)
    } catch (error) {
      if (error.status === 400) {
        setApplyModalProp({
          success: false,
          title: 'Oh Snap!',
          body: 'Complete your full profile before applying for a course!',
          button: 'Go to Profile',
        })
        setOpenApplyModal(true)
      } else if (error.status === 422) {
        const errors = error.data.errors
        setApplyModalProp({
          success: false,
          title: 'Oh Snap!',
          body: Object.values(errors)[0],
          button: 'Close',
        })
        setOpenApplyModal(true)
      } else {
        setApplyModalProp({
          success: false,
          title: `Oh Snap! ${error.status}`,
          body: JSON.stringify(error.data, null, 2),
          button: 'Close',
        })
        console.error(error)
        setOpenApplyModal(true)
      }
    }
  }

  const handleHRDFNavigation = (res) => {
    if (courseAdvertisement?.nex_edu_payment_schedule_id || res) {
      // CHECK IF USER HAS APPLIED FOR HRDF ELIGIBLITY OR NOT
      if (hrdfStatus?.hrdf_application_status_id) {
        navigate('/hrd/status', {
          state: {
            nex_edu_payment_schedule_id: courseAdvertisement?.nex_edu_payment_schedule_id || res,
            data: courseAdvertisement?.spots_remaining < 1 ? courseAdvertisement : null,
          },
        })
      } else {
        navigate('/hrd/introduction', {
          state: courseAdvertisement?.nex_edu_payment_schedule_id || res,
        })
      }
    } else {
      setApplyModalProp({
        success: false,
        title: 'Oh Snap!',
        body: 'Some errors occured! Please contact us for further information', //TBC
        button: 'Close',
      })
      setOpenApplyModal(true)
    }
  }

  const handleBuyNowHrdf = (res) => {
    if (courseAdvertisement?.nex_edu_payment_schedule_id || res) {
      navigate(`/payment-options/${courseAdvertisement?.nex_edu_payment_schedule_id || res}`, {
        state: courseAdvertisement?.nex_edu_payment_schedule_id || res,
      })
    } else {
      setApplyModalProp({
        success: false,
        title: 'Oh Snap!',
        body: 'Some errors occured! Please contact us for further information', //TBC
        button: 'Close',
      })
      setOpenApplyModal(true)
    }
  }

  const handleMakePaymentButton = async (res) => {
    if (courseAdvertisement.nex_edu_payment_schedule_id || res) {
      navigate(`/payment-summary/${courseAdvertisement.nex_edu_payment_schedule_id || res}`)
    } else {
      setApplyModalProp({
        success: false,
        title: 'Oh Snap!',
        body: 'Some errors occured! Please contact us for further information', //TBC
        button: 'Close',
      })
      setOpenApplyModal(true)
    }
  }

  const handlePurchaseCourse = async (e) => {
    if (isLoggedIn) {
      setLoading(true)
      try {
        await selfAppliedCourse({
          trainer_id: courseAdvertisement.trainer_id,
          nex_edu_course_id: courseAdvertisement.id,
          nex_edu_course_intake_id: courseAdvertisement?.nex_edu_course_intakes[0]?.id,
        }).then((res) => {
          if (res.data?.is_hrdf) {
            if (e && e.target.id === 'buyNow') {
              navigate(`/payment-options/${res.data.nex_edu_payment_schedule_id}`, {
                state: res.data.nex_edu_payment_schedule_id,
              })
            } else {
              handleHRDFNavigation(res.data.nex_edu_payment_schedule_id)
            }
          } else {
            handleMakePaymentButton(res.data.nex_edu_payment_schedule_id)
          }
        })
        await getCourseAdvertisements(param.id)
      } catch (e) {
        if (e.status === 400) {
          setApplyModalProp({
            success: false,
            title: 'Oh Snap!',
            body: 'Complete your full profile before applying for a course!',
            button: 'Go to Profile',
          })
          setOpenApplyModal(true)
        } else if (e.status === 422) {
          const errors = e.data.errors
          setApplyModalProp({
            success: false,
            title: 'Oh Snap!',
            body: Object.values(errors)[0],
            button: 'Close',
          })
          setOpenApplyModal(true)
        } else {
          console.error(e)
        }
      } finally {
        setLoading(false)
      }
    } else {
      navigate('/signin', { state: { from: 'courses/', id: courseAdvertisement.id } })
    }
  }

  const fetchHRDFStatus = async () => {
    try {
      const res = await getHrdfStatus(courseAdvertisement?.nex_edu_payment_schedule_id)
      setHRDFStatus(res)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (courseAdvertisement?.nex_edu_payment_schedule_id) {
      if (courseAdvertisement?.is_hrdf) {
        fetchHRDFStatus()
      } else {
        setHRDFStatus({})
      }
    } else {
      setHRDFStatus({})
    }
    // eslint-disable-next-line
  }, [courseAdvertisement])

  const DIRECTION_TYPE = {
    next: 'NEXT',
    prev: 'PREV',
  }

  const [sliderState, setSliderState] = useState({
    testimony: [
      {
        id: 1,
        img: Fairuz,
      },
      {
        id: 2,
        img: JunYang,
      },
      {
        id: 1,
        img: Fairuz,
      },
      {
        id: 2,
        img: JunYang,
      },
    ],
    current: 2,
    needTransition: true,
    direction: '',
  })

  const handleSliderTranslateEnd = () => {
    const { direction } = sliderState
    switch (direction) {
      case DIRECTION_TYPE.next:
        nextSlider()
        break
      case DIRECTION_TYPE.prev:
        prevSlider()
        break
      default:
        break
    }
  }

  const nextSlider = () => {
    const { current, testimony } = sliderState
    let _current = current
    // if (_current > testimony.length - 1) {
    _current -= 1
    const _testimony = [...testimony, ...testimony.slice(0, 1)].slice(-4)
    setSliderState({
      needTransition: false,
      current: _current,
      testimony: _testimony,
    })
    // }
  }

  const prevSlider = () => {
    const { current, testimony } = sliderState
    let _current = current
    // if (_current < 1) {
    _current += 1
    const _testimony = [...testimony.slice(-1), ...testimony].slice(0, 4)
    setSliderState({
      needTransition: false,
      current: _current,
      testimony: _testimony,
    })
    // }
  }

  const handleNext = () => {
    const { current, testimony } = sliderState
    let _current = current + 1
    if (_current > testimony.length) return
    setSliderState({
      testimony,
      needTransition: true,
      current: _current,
      direction: DIRECTION_TYPE.next,
    })
  }

  const handlePrev = () => {
    const { current, testimony } = sliderState
    let _current = current - 1
    if (_current < 0) return
    setSliderState({
      testimony,
      needTransition: true,
      current: _current,
      direction: DIRECTION_TYPE.prev,
    })
  }

  const translateVal = () => {
    return -(sliderState.current * 100)
  }

  const sliderStyle = () => {
    if (sliderState.needTransition) {
      return {
        transform: `translateX(${translateVal()}%)`,
        transition: 'transform 0.3s ease-in-out',
      }
    }
    return {
      transform: `translateX(${translateVal()}%)`,
    }
  }

  const handlePagination = (sliderId, index) => {
    if (sliderState?.testimony[0].id !== index) {
      if (sliderId === sliderState?.testimony[0].id) {
        handlePrev()
      } else {
        handleNext()
      }
      return
    }
  }
  let interval

  const handleNavigateEdu = () => {
    window.open(`${process.env.REACT_APP_EDU_URL}/course/${courseAdvertisement?.id}`)
  }

  useEffect(() => {
    // eslint-disable-next-line
    interval = setInterval(() => {
      handleNext()
    }, 8000)
    return () => {
      clearInterval(interval)
    }
  })

  const [isCourseAvailable, setIsCourseAvailable] = useState(true)

  const checkCourseAvailability = () => {
    const userCountry = ipCountry === 'MY' ? 'MY' : ipCountry === 'SG' ? 'SG' : 'INT'
    const courseCountry = courseAdvertisement?.exclusive_country === 'MY' ? 'MY' : courseAdvertisement?.exclusive_country === 'SG' ? 'SG' : 'INT'
    const isAvailable = userCountry === courseCountry
    // setIsCourseAvailable(isAvailable)
  }

  useEffect(() => {
    checkCourseAvailability()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseAdvertisement?.exclusive_country, ipCountry])

  const [showUnavailablePage, setShowUnavailablePage] = useState(false)

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowUnavailablePage(true)
    }, 3000)

    return () => clearTimeout(timeoutId)
  }, [isCourseAvailable])

  return (
    <div className="h-screen">
      {courseAdvertisement.course_title !== undefined && courseAdvertisement.trainer_name !== undefined && (
        <CustomHelmet
          title={`${courseAdvertisement.course_title} | ${courseAdvertisement.trainer_name} | adnexio EDU`}
          description={courseAdvertisement.course_description}
          url={`https://adnexio.jobs/courses/${param.id}`}
          image={courseAdvertisement.course_logo}
        />
      )}

      {loading === true && ipLoading === true && (
        <>
          <div
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: '999999999',
            }}
          >
            <Loader type="Oval" color="#09b7b7" height={55} width={55} />
          </div>
          <div
            style={{
              position: 'fixed',
              zIndex: '5',
              width: '100vw',
              height: '100vh',
              background: 'black',
              opacity: '0.7',
            }}
          />
        </>
      )}

      <EDUAlertModal
        onClose={() => {
          navigate('/courses')
        }}
        onClickButton={() => {
          handleApplyModalButton()
        }}
        open={openApplyModal}
        modalProp={modalProp}
      />

      <ShareSocialMedia
        jobAdvertisement={courseAdvertisement}
        handleClose={handleClose}
        open={open}
        setCopied={setCopied}
        copied={copied}
        edu={true}
      />

      <div>
        <HeaderEdu page="courses" />
      </div>
      {isCourseAvailable ? (
        <div className="">
          <div className="fixed top-0 hidden h-full w-5/12 flex-col items-center justify-center bg-adx-gray lg:flex">
            <div className="w-3/4 text-center">
              <div className="mx-auto mb-4 w-[100%]">
                <div className="flex items-center">
                  <Box
                    onClick={() => handlePrev()}
                    sx={{
                      width: '45px',
                      height: '45px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    <ChevronLeft fontSize="large" color="primary" />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      width: '100%',
                      overflow: 'hidden',
                      borderRadius: '10px',
                      marginX: '10px',
                    }}
                  >
                    <Box
                      onTransitionEnd={handleSliderTranslateEnd}
                      sx={{
                        padding: 0,
                        margin: 0,
                        display: 'flex',
                        width: '100%',
                        transform: 'translateX(0)',
                        ...sliderStyle(),
                      }}
                    >
                      {sliderState?.testimony?.map((testimony, index) => (
                        <Box
                          key={index}
                          sx={{
                            width: '100%',
                            flexShrink: 0,
                            display: 'flex',
                            justifyContent: 'center',
                            alignitems: 'center',
                          }}
                        >
                          <img src={testimony?.img} alt="testimonial" className="w-full" />
                        </Box>
                      ))}
                    </Box>
                  </Box>
                  <Box
                    onClick={() => handleNext()}
                    sx={{
                      width: '45px',
                      height: '45px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    <ChevronRight fontSize="large" color="primary" />
                  </Box>
                </div>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '10px',
                  }}
                >
                  {sliderState?.testimony?.slice(2)?.map((item, index) => (
                    <Box
                      key={index}
                      onClick={() => handlePagination(item?.id, index + 1)}
                      sx={{
                        height: '12px',
                        width: '12px',
                        boxSizing: 'border-box',
                        border: '1px solid #E2E2E2',
                        borderRadius: '50%',
                        cursor: 'pointer',
                        margin: '2px',
                        '&:hover': {
                          backgroundColor: '#8b4ac8',
                        },
                        backgroundColor: sliderState?.testimony[0].id === index + 1 ? 'primary.main' : '#E2E2E2',
                      }}
                    />
                  ))}
                </Box>
              </div>

              <div className="text-center">
                <p className="mb-2 text-black">For a better experience</p>
                <div className="flex justify-center gap-x-2">
                  <img
                    className="h-11 w-36 cursor-pointer rounded-lg"
                    src={apple_store_badges}
                    alt="app store"
                    onClick={() => appDownload('apple')}
                  />
                  <img
                    className="h-11 w-36 cursor-pointer rounded-lg"
                    src={google_play_badges}
                    alt="google play"
                    onClick={() => appDownload('android')}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="inline-block w-full ">
            <div className="inline-block lg:w-5/12"></div>
            <div className="inline-block w-full px-4 lg:w-7/12">
              <div className="my-4 hidden items-center justify-between lg:flex">
                <Button onClick={() => navigate('/courses')} variant="text" sx={{ textTransform: 'none' }} startIcon={<ChevronLeftIcon />}>
                  View all available courses
                </Button>
                {!preview && !courseAdvertisement?.nex_edu_application_status_id ? (
                  courseAdvertisement?.spots_remaining > 0 ? (
                    <Button
                      id="buyNow"
                      onClick={handlePurchaseCourse}
                      color="primary"
                      variant="contained"
                      sx={mui.btnContained}
                      disabled={!isLoggedIn || !profileComplete}
                    >
                      Buy Now
                    </Button>
                  ) : (
                    <Button color="primary" variant="contained" sx={mui.btnContained} disabled>
                      Full
                    </Button>
                  )
                ) : courseAdvertisement?.spots_remaining > 0 ? (
                  [1, 2, 4, 5].includes(courseAdvertisement?.nex_edu_application_status_id) ? (
                    <Button
                      onClick={
                        courseAdvertisement?.is_hrdf
                          ? () => handleBuyNowHrdf(courseAdvertisement?.nex_edu_payment_schedule_id)
                          : () => handleMakePaymentButton(courseAdvertisement?.nex_edu_payment_schedule_id)
                      }
                      color="primary"
                      variant="contained"
                      sx={mui.btnContained}
                      disabled={!isLoggedIn || !profileComplete}
                    >
                      Buy Now
                    </Button>
                  ) : (
                    [3].includes(courseAdvertisement?.nex_edu_application_status_id) && (
                      <Button color="primary" variant="contained" sx={mui.btnContained} disabled>
                        Purchased
                      </Button>
                    )
                  )
                ) : (
                  <Button color="primary" variant="contained" sx={mui.btnContained} disabled>
                    Full
                  </Button>
                )}
              </div>

              {!preview && (
                <div className="my-4 grid h-12 grid-cols-12 gap-x-4 lg:hidden">
                  <>
                    <div className="col-span-5 flex h-full items-center">
                      <Button onClick={() => handleClickOpen()} sx={mui.btn1} variant="outlined" startIcon={<ShareIcon />}>
                        Share
                      </Button>
                    </div>
                    <div className="col-span-7 flex h-full items-center">
                      {courseAdvertisement?.course_type === 'COURSE' ? (
                        <Button
                          onClick={handleNavigateEdu}
                          color="primary"
                          variant="contained"
                          sx={mui.btn2}
                          disabled={!isLoggedIn || !profileComplete}
                        >
                          Buy Now
                        </Button>
                      ) : courseAdvertisement?.spots_remaining < 1 ? (
                        <Button color="primary" variant="contained" sx={mui.btn2} disabled>
                          Full
                        </Button>
                      ) : !courseAdvertisement?.nex_edu_application_status_id ? (
                        <Button
                          onClick={handlePurchaseCourse}
                          color="primary"
                          variant="contained"
                          sx={mui.btn2}
                          disabled={!isLoggedIn || !profileComplete}
                        >
                          Buy Now
                        </Button>
                      ) : [1, 2, 4, 5].includes(courseAdvertisement?.nex_edu_application_status_id) ? (
                        <Button
                          onClick={() => handleMakePaymentButton(courseAdvertisement.nex_edu_payment_schedule_id)}
                          color="primary"
                          variant="contained"
                          sx={mui.btn2}
                          disabled={!isLoggedIn || !profileComplete}
                        >
                          Buy Now
                        </Button>
                      ) : (
                        [3].includes(courseAdvertisement?.nex_edu_application_status_id) && (
                          <Button color="primary" variant="contained" sx={mui.btn2} disabled>
                            Purchased
                          </Button>
                        )
                      )}
                    </div>
                  </>
                </div>
              )}

              {((isLoggedIn && !profileComplete) || !isLoggedIn) && (
                <div
                  className="rounded-sm p-2"
                  style={{
                    backgroundColor: '#FC4E4E14',
                    color: 'rgba(0, 0, 0, 0.87)',
                  }}
                >
                  <div className="flex items-center">
                    <ErrorOutlineIcon className="mr-2 text-red-500 " />
                    <p className="text-xs text-red-500 lg:text-sm">
                      {isLoggedIn && !profileComplete
                        ? 'Please complete your phone number and birth date before applying for a course.'
                        : 'Please login to your adnexio account to apply for this course.'}
                    </p>
                  </div>
                  <Link
                    to={{
                      pathname: isLoggedIn && !profileComplete ? '/profile' : '/signin',
                      state: { from: 'edu' },
                    }}
                  >
                    <p className="ml-8 text-xs text-blue-500 underline lg:text-sm">
                      {isLoggedIn && !profileComplete ? 'Go to Profile' : 'Login Now'}
                    </p>
                  </Link>
                </div>
              )}

              {courseAdvertisement.length !== 0 && (
                <div
                  className={`my-4 ${
                    courseAdvertisement?.course_type === 'COURSE' ? 'pb-4' : 'pb-0'
                  } w-full overflow-auto rounded-xl border bg-adx-darkGray`}
                >
                  {/* course summary */}
                  <div className="border-xl relative mx-auto box-border grid w-full grid-cols-3 rounded-t-md bg-adx-white py-4 shadow">
                    <div className="grid place-items-center justify-self-center">
                      {courseAdvertisement.course_logo === null || re.exec(courseAdvertisement.course_logo)[1] === 'pdf' ? (
                        <div className="flex h-20 w-20 items-center justify-center rounded-full bg-adx-white text-3xl text-black shadow">
                          {courseAdvertisement.course_title.charAt(0)}
                        </div>
                      ) : (
                        <img src={courseAdvertisement.course_logo} alt="course_logo" className="h-20 w-20 rounded-full object-cover shadow" />
                      )}
                    </div>
                    <div className="col-span-2 flex flex-col justify-center">
                      <p className="w-5/6 text-sm font-semibold capitalize text-adx-darkGreen lg:text-lg">{courseAdvertisement.course_title}</p>
                      <div className="mt-1 flex w-full flex-col pr-8 lg:flex-row lg:items-center lg:justify-between">
                        <p className="mb-2 text-xs font-semibold lg:mb-0 lg:text-sm">by {courseAdvertisement.trainer_name}</p>
                        {courseAdvertisement?.countdown > 0 && (
                          <StatusChipApplication
                            applicationStatusId={{
                              textStatus: courseAdvertisement?.countdown > 1 ? `${courseAdvertisement?.countdown} days left` : `1 day left`,
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-span-12">
                      <hr className="border-1 mx-4 my-5 border-adx-darkerGray" />
                    </div>
                    <div className="col-span-12 grid grid-cols-4 gap-2 px-4 ">
                      <div className="w-full justify-self-center ">
                        <div className="flex w-full flex-col items-center justify-center rounded-md bg-adx-lightTonedGreen py-1">
                          <p className="text-xs font-medium text-adx-darkGreen lg:text-sm">Spots</p>
                          <p className="text-center text-sm font-medium">
                            {courseAdvertisement?.course_type === 'COURSE' ? 'Unlimited' : courseAdvertisement.total_spots}
                          </p>
                        </div>
                      </div>
                      <div className="w-full justify-self-center ">
                        <div className="flex w-full flex-col items-center justify-center rounded-md bg-adx-lightTonedGreen py-1">
                          <p className="text-xs font-medium text-adx-darkGreen lg:text-sm">Duration</p>
                          <p className="text-center text-sm font-medium">{courseAdvertisement.course_length}</p>
                        </div>
                      </div>
                      <div className="w-full justify-self-center ">
                        <div className="flex w-full flex-col items-center justify-center rounded-md bg-adx-lightTonedGreen py-1">
                          <p className="text-xs font-medium text-adx-darkGreen lg:text-sm">Class</p>
                          <p className="text-center text-sm font-medium">
                            {courseAdvertisement?.course_type === 'COURSE' ? 'Online' : courseAdvertisement.course_setting}
                          </p>
                        </div>
                      </div>
                      <div className="w-full justify-self-center ">
                        <div className="flex w-full flex-col items-center justify-center rounded-md bg-adx-lightTonedGreen py-1">
                          <p className="text-xs font-medium text-adx-darkGreen lg:text-sm">Mode</p>
                          <p className="text-center text-sm font-medium">
                            {courseAdvertisement?.course_type === 'COURSE' ? 'Part Time' : courseAdvertisement.course_mode}
                          </p>
                        </div>
                      </div>
                    </div>

                    {courseAdvertisement?.is_hrdf ? (
                      <div className="col-span-12 mt-5 flex flex-col space-y-[20px] rounded-[4.5px] bg-adx-gray p-[20px]">
                        <div className=" flex flex-col items-center space-y-4 text-center md:flex-row md:space-y-0 md:space-x-[14px] md:text-left">
                          <img alt="hrd logo" src={hrdLogo} className="w-[60px]" />
                          {hrdfStatus && Object.keys(hrdfStatus).length > 1 ? (
                            <div className="flex flex-col space-y-[4px]">
                              <p className="text-sm font-medium lg:text-base">HRD Corp Incentive</p>
                              <p className="text-sm lg:text-base">
                                You have sent your application on {format(new Date(hrdfStatus?.created_at), 'dd/MM/yyyy')}
                              </p>
                            </div>
                          ) : (
                            <p className="text-sm lg:text-base">
                              This course is eligible for HRD Corp Claimable Course. Apply now to receive a comprehensive reimbursement of your
                              training costs. Enhance your professional development and upskill with this opportunity.{' '}
                              <span
                                onClick={() => {
                                  window.open('https://hrdcorp.gov.my/hrd-corp-claimable-course-2/')
                                }}
                                className="cursor-pointer text-adx-darkGreen underline"
                              >
                                Learn more
                              </span>
                            </p>
                          )}
                        </div>
                        <Button
                          variant="contained"
                          disabled={
                            !isLoggedIn ||
                            !profileComplete ||
                            (!hrdfStatus?.hrdf_application_status_id && courseAdvertisement.spots_remaining < 1) ||
                            [3].includes(courseAdvertisement?.nex_edu_application_status_id)
                          }
                          onClick={
                            !courseAdvertisement?.nex_edu_application_status_id
                              ? () => handlePurchaseCourse()
                              : () => handleHRDFNavigation(courseAdvertisement.nex_edu_payment_schedule_id)
                          }
                          sx={{
                            backgroundColor: '#FCD94E',
                            width: '100%',
                            color: '#1B8379',
                            boxShadow: 'none',
                            minHeight: '45px',
                            '&:hover': {
                              color: '#FCD94E',
                            },
                          }}
                        >
                          {[3].includes(courseAdvertisement?.nex_edu_application_status_id)
                            ? 'Purchased'
                            : !hrdfStatus?.hrdf_application_status_id && courseAdvertisement.spots_remaining < 1
                            ? 'Full'
                            : hrdfStatus && Object.keys(hrdfStatus).length > 1
                            ? 'View status'
                            : 'Apply HRD Corp incentive'}
                        </Button>
                      </div>
                    ) : (
                      <div className="col-span-12 mt-5 px-4">
                        {courseAdvertisement?.course_type === 'COURSE' ? (
                          <Button
                            onClick={handleNavigateEdu}
                            color="primary"
                            variant="contained"
                            sx={{ ...mui.btnContained, width: '100%' }}
                            disabled={!isLoggedIn || !profileComplete}
                          >
                            Buy Now
                          </Button>
                        ) : (
                          <Button
                            onClick={
                              !courseAdvertisement?.nex_edu_application_status_id
                                ? () => handlePurchaseCourse()
                                : [1, 2, 4, 5].includes(courseAdvertisement?.nex_edu_application_status_id)
                                ? () => handleMakePaymentButton(courseAdvertisement.nex_edu_payment_schedule_id)
                                : null
                            }
                            color="primary"
                            variant="contained"
                            sx={{ ...mui.btnContained, width: '100%' }}
                            disabled={
                              !isLoggedIn ||
                              !profileComplete ||
                              courseAdvertisement?.spots_remaining < 1 ||
                              [3].includes(courseAdvertisement?.nex_edu_application_status_id)
                            }
                          >
                            {courseAdvertisement?.spots_remaining < 1
                              ? 'Full'
                              : !courseAdvertisement?.nex_edu_application_status_id ||
                                [1, 2, 4, 5].includes(courseAdvertisement?.nex_edu_application_status_id)
                              ? courseAdvertisement?.lump_sum_discount === '0'
                                ? 'Buy Now'
                                : `Click here to get ${courseAdvertisement?.currency_code}${courseAdvertisement?.lump_sum_discount} OFF`
                              : [3].includes(courseAdvertisement?.nex_edu_application_status_id) && 'Purchased'}
                          </Button>
                        )}
                      </div>
                    )}
                    <div className="absolute top-2 right-2 hidden lg:block">
                      <IconButton onClick={() => handleClickOpen()} sx={mui.ShareIconButton} size="large">
                        <ShareIcon />
                      </IconButton>
                    </div>
                  </div>
                  {courseAdvertisement?.course_type !== 'COURSE' && (
                    <>
                      <div className="my-2 mt-5 space-y-1 px-7">
                        <p className="text-xs font-semibold lg:text-sm">Application Guide</p>
                        <p className="whitespace-pre-line text-xs lg:text-sm">
                          1. Sign up/Login to adnexio accounts.
                          <br />
                          2. Complete your profile with phone number and birth date.
                          <br />
                          3. Click “Buy Now” to purchase the course.
                          <br />
                          4. Make payment to confirm your enrollment and gain access to the course.
                        </p>
                        <br />
                        <p className="whitespace-pre-line text-justify text-xs lg:text-sm" style={{ lineHeight: '1.55rem' }}>
                          <span className="text-blue-500 underline">
                            <Link to="/contact-us">Contact us</Link>
                          </span>{' '}
                          if you have any further questions!
                        </p>
                      </div>
                      <hr className="border-1 mx-4 my-5 border-adx-darkerGray" />
                    </>
                  )}

                  <div className="my-2 mt-5 space-y-1 px-7">
                    <p className="text-xs font-semibold lg:text-sm">Description</p>
                    <p className="whitespace-pre-line text-justify text-xs lg:text-sm">{courseAdvertisement.course_description}</p>
                    {courseAdvertisement?.course_type !== 'COURSE' && courseAdvertisement?.class_schedule && (
                      <>
                        <p className="text-xs font-semibold lg:text-sm">Class Schedule</p>
                        <p className="whitespace-pre-line text-justify text-xs lg:text-sm" style={{ lineHeight: '1.55rem' }}>
                          {courseAdvertisement?.class_schedule}
                        </p>
                      </>
                    )}
                    {courseAdvertisement?.course_type !== 'COURSE' && courseAdvertisement?.grad_requirement && (
                      <>
                        <p className="text-xs font-semibold lg:text-sm">{courseAdvertisement.course_title} - Graduation Requirement</p>
                        <p className="whitespace-pre-line text-justify text-xs lg:text-sm" style={{ lineHeight: '1.55rem' }}>
                          {courseAdvertisement?.grad_requirement}
                        </p>
                      </>
                    )}
                  </div>
                  <hr className="border-1 mx-4 my-5 border-adx-darkerGray" />

                  <div className="my-2 space-y-2 px-7">
                    {courseAdvertisement?.course_type !== 'COURSE' ? (
                      <>
                        <p className="text-xs font-semibold lg:text-sm">Benefits</p>
                        <p className="whitespace-pre-line text-xs lg:text-sm" style={{ lineHeight: '1.55rem' }}>
                          {courseAdvertisement.course_benefits}
                        </p>
                      </>
                    ) : (
                      <>
                        <p className="text-xs font-semibold lg:text-sm">Learning objectives</p>
                        <ul className="ml-4 list-outside list-disc space-y-1 text-xs lg:text-sm ">
                          {courseAdvertisement?.objectives?.map((objective, index) => (
                            <li key={index}>{objective}</li>
                          ))}
                        </ul>
                      </>
                    )}
                  </div>
                  <hr className="border-1 mx-4 my-5 border-adx-darkerGray" />

                  <div className="my-2 space-y-2 px-7">
                    <p className="text-xs font-semibold lg:text-sm">Requirements</p>
                    <p className="whitespace-pre-line text-xs lg:text-sm" style={{ lineHeight: '1.55rem' }}>
                      {courseAdvertisement?.course_type !== 'COURSE' ? (
                        courseAdvertisement.course_requirements
                      ) : (
                        <ul className="ml-4 list-outside list-disc space-y-1 text-xs lg:text-sm ">
                          {courseAdvertisement?.course_requirements?.split('\n')?.map((requirement, index) => (
                            <li key={index}>{requirement}</li>
                          ))}
                        </ul>
                      )}
                    </p>
                  </div>
                  {courseAdvertisement?.course_type !== 'COURSE' && <hr className="border-1 mx-4 my-5 border-adx-darkerGray" />}
                  {courseAdvertisement?.course_type !== 'COURSE' && (
                    <>
                      <div className="my-2 space-y-2 px-7">
                        <p className="text-xs font-semibold lg:text-sm">Career Paths</p>
                        <p className="whitespace-pre-line text-xs lg:text-sm" style={{ lineHeight: '1.55rem' }}>
                          {courseAdvertisement.course_careers}
                        </p>
                      </div>
                      <hr className="border-1 mx-4 my-5 border-adx-darkerGray" />

                      <div className="my-2 space-y-2 px-7">
                        <p className="text-xs font-semibold lg:text-sm">Teaching Method</p>
                        <p className="whitespace-pre-line text-xs lg:text-sm" style={{ lineHeight: '1.55rem' }}>
                          {courseAdvertisement.course_teaching_method}
                        </p>
                      </div>
                      <hr className="border-1 mx-4 my-5 border-adx-darkerGray" />

                      <div className="my-2 space-y-2 px-7">
                        <p className="text-xs font-semibold lg:text-sm">Intake</p>
                        {courseAdvertisement.nex_edu_course_intakes?.map((item, index) => {
                          const start_date = moment(item.start_date).format('DD MMMM YYYY')
                          return (
                            <p key={index} className="whitespace-pre-line text-justify text-xs lg:text-sm" style={{ lineHeight: '1.55rem' }}>
                              {start_date}
                            </p>
                          )
                        })}
                        <p className="whitespace-pre-line text-justify text-xs lg:text-sm" style={{ lineHeight: '1.55rem' }}>
                          More intakes to open in the future!
                        </p>
                      </div>
                      <hr className="border-1 mx-4 my-5 border-adx-darkerGray" />

                      <div className="my-2 space-y-2 px-7">
                        <p className="text-xs font-semibold lg:text-sm">Length of Course</p>
                        <p className="whitespace-pre-line text-justify text-xs lg:text-sm" style={{ lineHeight: '1.55rem' }}>
                          {courseAdvertisement.course_length}
                        </p>
                      </div>
                      <hr className="border-1 mx-4 my-5 border-adx-darkerGray" />

                      {courseAdvertisement?.nex_edu_course_testimonials && (
                        <>
                          <div className="my-2 space-y-2 px-7">
                            <p className="text-xs font-semibold lg:text-sm">Testimonials</p>
                            <video width="750" height="500" controls key={1} controlsList="nodownload">
                              <source src={courseAdvertisement?.nex_edu_course_testimonials} type="video/mp4" />
                            </video>
                          </div>
                          <hr className="border-1 mx-4 my-5 border-adx-darkerGray" />
                        </>
                      )}

                      {/* <div className="my-2 space-y-2 px-7">
                        <p className="text-xs font-semibold lg:text-sm">Fee</p>
                        <p
                          className="text-xs text-justify whitespace-pre-line lg:text-sm"
                          style={{ lineHeight: '1.55rem' }}
                        >
                          {`Total Fee: ${courseAdvertisement?.currency_code}${courseAdvertisement.course_fee}, with 2 payment plan options:`}
                        </p>
                        <p
                          className="text-xs text-justify whitespace-pre-line lg:text-sm"
                          style={{ lineHeight: '1.55rem' }}
                        >
                          {`i) ${courseAdvertisement?.currency_code}${
                            parseInt(courseAdvertisement.course_fee) -
                            courseAdvertisement.lump_sum_discount
                          } for a one-off payment`}
                        </p>
                        <p
                          className="text-xs text-justify whitespace-pre-line lg:text-sm"
                          style={{ lineHeight: '1.55rem' }}
                        >
                          {`ii) ${courseAdvertisement?.currency_code}${parseInt(
                            courseAdvertisement.course_fee / courseAdvertisement.num_of_installments
                          )} x ${courseAdvertisement.num_of_installments} via installments`}
                        </p>
                        <p
                          className="text-xs text-justify whitespace-pre-line lg:text-sm"
                          style={{ lineHeight: '1.55rem' }}
                        >
                          Prices include 8% SST
                        </p>
                      </div>
                      <hr className="mx-2 my-5 border-1 border-adx-darkerGray lg:mx-4" /> */}

                      {courseAdvertisement?.tnc_link && (
                        <div className="my-2 space-y-2 px-7">
                          <div className="mb-8 space-y-2">
                            <p className="text-xs font-semibold lg:text-sm">Terms and Conditions</p>
                            <p className="whitespace-pre-line text-justify text-xs lg:text-sm" style={{ lineHeight: '1.55rem' }}>
                              Read your full{' '}
                              <span className="cursor-pointer text-blue-500" onClick={() => window.open(courseAdvertisement?.tnc_link)}>
                                Terms & Conditions
                              </span>{' '}
                              here
                            </p>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}

              <div className="bg-white pt-16 pb-12">
                <div className="" id="lessonPlanContainer">
                  <p className="mb-4 text-xs font-semibold lg:text-lg">Browse Lesson Plan</p>
                  {courseAdvertisement?.nex_edu_course_topics?.map((item, index) => (
                    <Accordion
                      key={index}
                      sx={{ ...mui.accordionBox, ...mui.disableAccordion }}
                      expanded={expandedPanel === index}
                      onChange={handleAccordionChange(index)}
                    >
                      <AccordionSummary sx={{ ...mui.accordionTitle, ...mui.disableAccordionSummary }} expandIcon={<ExpandMoreIcon />}>
                        <p className="text-xs font-semibold lg:text-sm">{item.topic_title}</p>
                      </AccordionSummary>
                      <AccordionDetails sx={mui.accordionDescription}>
                        <p className="whitespace-pre-line text-xs lg:text-sm" style={{ lineHeight: '1.55rem' }}>
                          {item.topic_description}
                        </p>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>
              </div>
            </div>
            {/* Joined Waitlist Popper to remove from waitlist */}
            <Popper
              open={openPopper}
              anchorEl={anchorRefPopper.current}
              transition
              disablePortal
              placement="bottom-end"
              className="z-50 w-4/12 min-w-min max-w-sm rounded-xl shadow-xl lg:w-auto lg:max-w-sm"
              sx={mui.popperTwo}
            >
              {({ TransitionProps }) => (
                <Grow {...TransitionProps} style={{ transformOrigin: 'right top' }}>
                  <div className="rounded-xl bg-white shadow-xl">
                    <ClickAwayListener onClickAway={handleDismissPopperModal}>
                      <MenuList autoFocusItem={openPopper}>
                        <MenuItem
                          onClick={() => {
                            setOpenPopper(false)
                            handleRemoveWaitlist(courseAdvertisement?.nex_edu_application_id)
                          }}
                          sx={mui.settingsEDUBtn}
                        >
                          Remove from waitlist
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </div>
                </Grow>
              )}
            </Popper>
          </div>
        </div>
      ) : (
        <>
          {!showUnavailablePage ? (
            <>
              <div
                style={{
                  position: 'fixed',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: '999999999',
                }}
              >
                <Loader type="Oval" color="#09b7b7" height={55} width={55} />
              </div>
              <div
                style={{
                  position: 'fixed',
                  zIndex: '5',
                  width: '100vw',
                  height: '100vh',
                  background: 'black',
                  opacity: '0.7',
                }}
              />
            </>
          ) : (
            <div className="absolute top-0 h-full w-full bg-adx-darkGreen">
              <div className="flex h-full w-full flex-col items-center justify-center px-6 text-5xl font-bold text-adx-white lg:text-6xl">
                <div>Page Not Found</div>
                <div className="mb-5 text-xl font-medium lg:text-2xl">Sorry, this course is not available in your country</div>
                <Link to="/courses">
                  <ButtonBase
                    sx={{
                      background: '#FCD94E',
                      borderRadius: '5px',
                      fontSize: '1rem',
                      padding: '13px 15px',
                      color: '#1B8379',
                      fontWeight: '500',
                      boxShadow: '2px 4px 2px rgba(0,0,0,0.1)',
                      '@media (max-width:1024px)': {
                        fontSize: '1rem',
                      },
                    }}
                  >
                    Back to courses
                  </ButtonBase>
                </Link>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default CourseAdsDetails
