/**
 * THIS CONTEXT IS CURRENTLY NOT BEING USED
 */

import { createContext } from 'react'
// import { useState, useEffect } from 'react'
// import { ipLookup, getIpAddress } from 'services/api/ip'

export const IpContext = createContext()

const IpContextProvider = (props) => {
  // const [ipCountry, setIpCountry] = useState(null)
  // const [ipLoading, setIpLoading] = useState(false)

  const ipCountry = 'MY'
  const ipLoading = false

  // const fetchData = async () => {
  //   try {s
  //     setIpLoading(true)
  //     await getIpAddress().then(async (res) => {
  //       if (res) {
  //         await ipLookup(res).then((res) => {
  //           setIpCountry(res.country)
  //         })
  //       }
  //     })
  //   } catch (e) {
  //     console.error(e)
  //   } finally {
  //     setIpLoading(false)
  //   }
  // }

  // useEffect(() => {
  //   fetchData()
  // }, [])

  return (
    <IpContext.Provider
      value={{
        ipCountry,
        ipLoading,
      }}
    >
      {props.children}
    </IpContext.Provider>
  )
}

export default IpContextProvider
