import { useState, useEffect, useContext, useRef } from 'react'
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom'
import Loader from 'react-loader-spinner'
import { Link as RSLink } from 'react-scroll'
import moment from 'moment'

import { makeStyles } from '@mui/styles'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Button from '@mui/material/Button'
import Grow from '@mui/material/Grow'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import ClickAwayListener from '@mui/material/ClickAwayListener'

import CustomHelmet from 'components/smart/CustomHelmet'
import { authContext } from 'contexts/AuthContext'
import {
  courseApplications,
  selfAppliedCourse,
  selfWithdrawApplication,
} from 'services/api/course_application'
import EDUAlertModal from 'modules/edu/components/AlertModal'
import HeaderEdu from 'modules/edu/components/Header/view/HeaderEdu'
import course_structure from 'services/constant/course_structure.json'

import apple_store_badges from '../assets/app-store-badge.png'
import google_play_badges from '../assets/google-play-badge.png'
import MingYuan from 'pages/my_classes/assets/MingYuan.png'

const useStyles = makeStyles({
  btn1: {
    color: '#1B8379',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    height: 40,
    width: '100%',
    boxShadow: 'none',
    fontSize: '12px',
  },
  btn2: {
    textTransform: 'capitalize',
    height: 40,
    width: '100%',
    boxShadow: 'none',
    fontSize: '12px',
  },
  btnContained: {
    textTransform: 'capitalize',
    padding: '8px 18px',
    boxShadow: 'none',
    fontSize: '12px',
  },
  btnShareDesktop: {
    color: '#E2E2E2',
    backgroundColor: '#1B8379',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    height: 40,
    width: '100%',
    '&:hover': {
      backgroundColor: '#1B8379',
    },
    '&:focus': {
      outline: 'none',
      color: 'none',
      backgroundColor: 'none',
    },
  },
  btnViewCourses: {
    textTransform: 'capitalize',
  },
  btnCourseStructure: {
    width: '100%',
    fontSize: 16,
  },
  accordionBox: {
    border: 'none',
    boxShadow: 'none',

    '&.MuiAccordion-root:before': {
      backgroundColor: 'white',
    },
  },
  accordionTitle: {
    border: 'solid #D9D9D9 1px',
    marginTop: 0,
    borderRadius: 4,
  },
  accordionDescription: {
    backgroundColor: '#F5F5F5',
  },
  disableAccordion: {
    '&.Mui-disabled': {
      backgroundColor: 'white',
      opacity: 1,
    },
  },
  disableAccordionSummary: {
    opacity: '1 !important',
  },
  btnAdnexioGig: {
    background: '#FCD94E',
    color: '#1B8379',
    textTransform: 'capitalize',
    padding: '8px 18px',
    boxShadow: 'none',
    fontSize: '12px',
    '&:hover': {
      background: '#FCD94E',
      boxShadow: 'none',
    },
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
  },
  btnAdnexioOutline: {
    background: '#FFFFFF',
    color: '#1B8379',
    textTransform: 'capitalize',
    padding: '8px 18px',
    boxShadow: 'none',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#1B8379',
    fontSize: '12px',
    '&:hover': {
      background: '#E2EAE9',
      boxShadow: 'none',
    },
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
  },
})

const CourseApplicationDetails = () => {
  const navigate = useNavigate()
  const classes = useStyles()
  const param = useParams()
  const { auth } = useContext(authContext)
  const { isAuth: isLoggedIn, profileStatus } = auth
  const [courseAdvertisement, setCourseAdvertisement] = useState([])
  const [loading, setLoading] = useState(false)
  const [preview, setPreview] = useState(false)
  const location = useLocation()
  const [openApplyModal, setOpenApplyModal] = useState(false)
  const [modalProp, setApplyModalProp] = useState({
    success: null,
    title: '',
    body: '',
    button: '',
  })

  const [expandedPanel, setExpandedPanel] = useState(false)
  const [openPopper, setOpenPopper] = useState(false)

  const anchorRefPopper = useRef(null)

  const handleDismissPopperModal = (event) => {
    if (anchorRefPopper.current && anchorRefPopper.current.contains(event.target)) {
      return
    }
    setOpenPopper(false)
  }

  // Get image file extension
  let re = /(?:\.([^.]+))?$/

  const handleApplyModalButton = async () => {
    if (modalProp.button === 'Close' || modalProp.button === 'Pay Later') {
      try {
        setOpenApplyModal(false)
        setApplyModalProp({
          success: null,
          title: '',
          body: '',
          button: '',
        })
      } catch (e) {
        if (e.status === 400) {
          setOpenApplyModal(true)
          setApplyModalProp({
            success: false,
            title: 'Error',
            body: e.data.message,
            button: 'Okay',
          })
        }
      }
    } else if (modalProp.button === 'Go to Profile') {
      navigate('/profile')
    } else if (modalProp.button === 'Okay') {
      setOpenApplyModal(false)
      setApplyModalProp({
        success: null,
        title: '',
        body: '',
        button: '',
      })
    }
  }

  const handleApplyModalSecondButton = () => {
    if (modalProp.secondButton === 'View My Class') {
      navigate('/my-classes')
    } else if (modalProp.secondButton === 'Make Payment') {
      navigate(`/payment-summary/${courseAdvertisement.nex_edu_payment_schedule_id}`)
    }
  }

  const onCloseAlertModal = async () => {
    setOpenApplyModal(false)
    setApplyModalProp({
      success: null,
      title: '',
      body: '',
      button: '',
    })
  }

  useEffect(() => {
    if (location.pathname.includes('preview')) {
      setPreview(true)
    }
  }, [location.pathname])

  useEffect(() => {
    getCourseAdvertisements(param.id)
  }, [param.id, courseAdvertisement.applied])

  const getCourseAdvertisements = async (id) => {
    try {
      setLoading(true)
      const response = await courseApplications('get', null, id)
      setCourseAdvertisement(response.data)
    } catch (e) {
      if (e.status === 404) {
        window.location.href = '/course-not-found'
      }
    } finally {
      setLoading(false)
    }
  }

  const appDownload = (app) => {
    if (app === 'apple') {
      window.open('https://apps.apple.com/my/app/adnexio-jobs/id1500510440', '_blanks')
    } else if (app === 'android') {
      window.open('https://play.google.com/store/apps/details?id=org.adnexio.jobs', '_blanks')
    }
  }

  const StatusPillApplication = () => {
    let textStatus
    let color
    let backgroundColor
    switch (courseAdvertisement?.nex_edu_application_status_id) {
      case 1:
        textStatus = 'Application under review' // Yellow
        color = '#CCAB02'
        backgroundColor = '#FCC84E29'
        break
      case 2:
        textStatus = 'To confirm participation' // Blue
        color = '#0FA6F5'
        backgroundColor = '#0088FF1C'
        break
      case 3:
        textStatus = 'Participation confirmed' // Green
        color = '#1B8379'
        backgroundColor = '#E2EAE9'
        break
      case 4:
        textStatus = 'Application is unsuccessful' // Red
        color = '#FC4E4E'
        backgroundColor = '#FC4E4E14'
        break
      case 5:
        textStatus = 'Waitlisted' // Blue
        color = '#8A8A8A'
        backgroundColor = '#E5E5E5'
        break
      default:
        textStatus = 'No Status'
    }
    return (
      <p
        className="no-wrap inline-block rounded-full px-2 py-1 text-center text-xs "
        style={{ color, backgroundColor }}
      >
        {textStatus}
      </p>
    )
  }

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : false)
  }

  // Remove Waitlist API Call
  const handleRemoveWaitlist = async () => {
    setOpenPopper(false)
    try {
      await selfWithdrawApplication(courseAdvertisement?.id)
      navigate(`/courses`)
    } catch (error) {
      console.log('Remove Error: ', error.data)
      if (error.status === 400) {
        setApplyModalProp({
          success: false,
          title: 'Oh Snap!',
          body: 'Complete your full profile before applying for a course!',
          button: 'Go to Profile',
        })
        setOpenApplyModal(true)
      } else if (error.status === 422) {
        const errors = error.data.errors
        setApplyModalProp({
          success: false,
          title: 'Oh Snap!',
          body: Object.values(errors)[0],
          button: 'Close',
        })
        setOpenApplyModal(true)
      } else {
        setApplyModalProp({
          success: false,
          title: `Oh Snap! ${error.status}`,
          body: JSON.stringify(error.data, null, 2),
          button: 'Close',
        })
        setOpenApplyModal(true)
      }
    }
  }

  // Apply Now
  const processSelfApplied = async (data) => {
    if (isLoggedIn) {
      setLoading(true)
      try {
        await selfAppliedCourse(data)
        setApplyModalProp({
          success: true,
          title: 'Awesome!',
          body: `Your application has been submitted. To secure your spot in the course, you have to make payment within 2 days.`,
          button: 'Close',
        })
        getCourseAdvertisements(param.id)
        setOpenApplyModal(true)
      } catch (e) {
        console.log('e', e)
        if (e.status === 400) {
          setApplyModalProp({
            success: false,
            title: 'Oh Snap!',
            body: 'Complete your full profile before applying for a course!',
            button: 'Go to Profile',
          })
          setOpenApplyModal(true)
        } else if (e.status === 422) {
          const errors = e.data.errors
          setApplyModalProp({
            success: false,
            title: 'Oh Snap!',
            body: Object.values(errors)[0],
            button: 'Close',
          })
          setOpenApplyModal(true)
        }
        setLoading(false)
      }
    } else {
      navigate('/signin', { state: { from: `courses/${param.id}` } })
    }
  }

  // Apply Again after being rejected or unsuccessful
  const handleApplyAgainButton = () => {
    processSelfApplied({
      trainer_id: courseAdvertisement.trainer_id,
      nex_edu_course_id: courseAdvertisement.nex_edu_course_id,
      nex_edu_course_intake_id: courseAdvertisement?.nex_edu_course_intakes[0]?.id,
    })
  }

  const handleMakePaymentButton = () => {
    navigate(`/payment-summary/${courseAdvertisement.nex_edu_payment_schedule_id}`)
  }

  return (
    <div className="h-screen">
      {courseAdvertisement.course_title !== undefined &&
        courseAdvertisement.trainer_name !== undefined && (
          <CustomHelmet
            title={`${courseAdvertisement.course_title} | ${courseAdvertisement.trainer_name} | adnexio EDU`}
            description={courseAdvertisement.course_description}
            url={`https://adnexio.jobs/course-application/${param.id}`}
            image={courseAdvertisement.course_logo}
          />
        )}
      {loading === true && (
        <>
          <div
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: '999999999',
            }}
          >
            <Loader type="Oval" color="#09b7b7" height={55} width={55} />
          </div>
          <div
            style={{
              position: 'fixed',
              zIndex: '5',
              width: '100vw',
              height: '100vh',
              background: 'black',
              opacity: '0.7',
            }}
          ></div>
        </>
      )}

      <EDUAlertModal
        onClose={() => {
          onCloseAlertModal()
        }}
        onClickButton={() => {
          handleApplyModalButton()
        }}
        onClickSecondButton={() => {
          handleApplyModalSecondButton()
        }}
        open={openApplyModal}
        modalProp={modalProp}
      />

      <div>
        <HeaderEdu page="courses" />
      </div>

      <div className="">
        <div
          className="fixed top-0 hidden h-full w-5/12 flex-col items-center justify-center bg-adx-gray lg:flex"
          style={{}}
        >
          <div className="w-2/3">
            <img src={MingYuan} alt="..." className="mx-auto mb-4 w-96 rounded-lg" />

            <div className="text-center">
              <p className="mb-2 text-black">For a better experience</p>
              <div className="flex justify-center gap-x-2">
                <img
                  className="h-11 w-36 cursor-pointer rounded-lg"
                  src={apple_store_badges}
                  alt="..."
                  onClick={() => appDownload('apple')}
                />
                <img
                  className="h-11 w-36 cursor-pointer rounded-lg"
                  src={google_play_badges}
                  alt="..."
                  onClick={() => appDownload('android')}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="inline-block w-full ">
          <div className="inline-block lg:w-5/12"></div>

          <div className="inline-block w-full px-4 lg:w-7/12">
            {/* Application being reviewed until status changes */}
            {[1].includes(courseAdvertisement?.nex_edu_application_status_id) && (
              <div className="my-4 mr-2 flex justify-end">
                {!preview && (
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.btnAdnexioGig}
                    disabled
                  >
                    Under review
                  </Button>
                )}
              </div>
            )}

            {/* To confirm participation means Make Payment */}
            {[2].includes(courseAdvertisement?.nex_edu_application_status_id) && (
              <div className="my-4 mr-2 flex justify-end">
                {!preview && (
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.btnAdnexioGig}
                    onClick={handleMakePaymentButton}
                    disabled={!courseAdvertisement?.nex_edu_payment_schedule_id}
                  >
                    Make Payment
                  </Button>
                )}
              </div>
            )}

            {/* Successfully registered for course */}
            {[3].includes(courseAdvertisement?.nex_edu_application_status_id) && (
              <div className="my-4 mr-2 flex justify-end">
                {!preview && (
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.btnAdnexioGig}
                    disabled
                  >
                    Confirmed
                  </Button>
                )}
              </div>
            )}
            {[4].includes(courseAdvertisement?.nex_edu_application_status_id) && (
              <div className="my-4 mr-2 flex justify-end">
                {!preview && (
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.btnAdnexioGig}
                    onClick={handleApplyAgainButton}
                  >
                    Apply again
                  </Button>
                )}
              </div>
            )}

            {/* Waitlisted */}
            {[5].includes(courseAdvertisement?.nex_edu_application_status_id) && (
              <div className="my-4 mr-2 flex justify-end">
                {!preview && (
                  <Button
                    color="primary"
                    variant="outlined"
                    className={classes.btnAdnexioOutline}
                    onClick={() => setOpenPopper((prevOpen) => !prevOpen)}
                    ref={anchorRefPopper}
                  >
                    Added to waitlist
                  </Button>
                )}
              </div>
            )}

            {isLoggedIn && !profileStatus && (
              <div
                className="flex items-center rounded-sm p-2"
                style={{
                  backgroundColor: '#FC4E4E14',
                  color: 'rgba(0, 0, 0, 0.87)',
                }}
              >
                <ErrorOutlineIcon className="mr-2 text-red-500 " />
                <p className="text-xs font-semibold text-red-500 lg:text-sm">
                  Please complete your profile before applying to a course
                </p>
              </div>
            )}

            {courseAdvertisement.length !== 0 && (
              <div className="my-4 min-h-screen w-full overflow-auto rounded-xl border bg-adx-darkGray">
                <div className="border-xl relative mx-auto box-border grid w-full grid-cols-3 rounded-t-md bg-adx-white py-4 shadow">
                  <div className="grid place-items-center justify-self-center">
                    {courseAdvertisement.course_logo === null ||
                    re.exec(courseAdvertisement.course_logo)[1] === 'pdf' ? (
                      <div className="flex h-20 w-20 items-center justify-center rounded-full bg-adx-white text-3xl text-black shadow">
                        {courseAdvertisement.course_title.charAt(0)}
                      </div>
                    ) : (
                      <img
                        src={courseAdvertisement.course_logo}
                        alt="course_logo"
                        className="h-20 w-20 rounded-full object-cover shadow"
                      />
                    )}
                  </div>
                  <div className="col-span-2 flex flex-col justify-center">
                    <p className="w-5/6 text-sm font-semibold capitalize text-adx-darkGreen lg:text-lg">
                      {courseAdvertisement.course_title}
                    </p>
                    <div className="mt-1 flex w-full flex-col pr-8 lg:flex-row lg:items-center lg:justify-between">
                      <p className="mb-2 text-xs font-semibold lg:mb-0 lg:text-sm">
                        by {courseAdvertisement.trainer_name}
                      </p>
                      {courseAdvertisement?.nex_edu_application_status_id && (
                        <StatusPillApplication />
                      )}
                    </div>
                  </div>
                  <div className="col-span-12">
                    <hr className="border-1 mx-4 my-5 border-adx-darkerGray" />
                  </div>
                  <div className="col-span-12 grid grid-cols-4 gap-2 px-4 ">
                    <div className="w-full justify-self-center ">
                      <div className="flex w-full flex-col items-center justify-center rounded-md bg-adx-lightTonedGreen py-1">
                        <p className="text-xs font-semibold text-adx-darkGreen lg:text-sm">Spots</p>
                        <p className="text-center text-sm font-bold lg:text-base">
                          {courseAdvertisement.total_spots}
                        </p>
                      </div>
                    </div>
                    <div className="w-full justify-self-center ">
                      <div className="flex w-full flex-col items-center justify-center rounded-md bg-adx-lightTonedGreen py-1">
                        <p className="text-xs font-semibold text-adx-darkGreen lg:text-sm">
                          Duration
                        </p>
                        <p className="text-center text-sm font-bold lg:text-base">
                          {courseAdvertisement.course_duration} month
                          {courseAdvertisement.course_duration > 1 && 's'}
                        </p>
                      </div>
                    </div>
                    <div className="w-full justify-self-center ">
                      <div className="flex w-full flex-col items-center justify-center rounded-md bg-adx-lightTonedGreen py-1">
                        <p className="text-center text-xs font-semibold text-adx-darkGreen lg:text-sm">
                          Class
                        </p>
                        <p className="text-sm font-bold lg:text-base">
                          {courseAdvertisement.course_setting}
                        </p>
                      </div>
                    </div>
                    <div className="w-full justify-self-center ">
                      <div className="flex w-full flex-col items-center justify-center rounded-md bg-adx-lightTonedGreen py-1">
                        <p className="text-xs font-semibold text-adx-darkGreen lg:text-sm">Mode</p>
                        <p className="text-center text-sm font-bold lg:text-base">
                          {courseAdvertisement.course_mode}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-span-12 mt-5 px-4">
                    <RSLink
                      to="lessonPlanContainer"
                      spy={true}
                      smooth={true}
                      offset={50}
                      duration={500}
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        className={`${classes.btnContained} ${classes.btnCourseStructure}`}
                      >
                        Lesson Plan
                      </Button>
                    </RSLink>
                  </div>
                </div>

                <div className="my-2 mt-5 space-y-1 px-7">
                  <p className="text-xs font-semibold lg:text-sm">Application Guide</p>
                  <p className="whitespace-pre-line text-xs lg:text-sm">
                    1. Complete your profile picture, phone number and upload a CV in your jobseeker
                    profile page.
                    <br />
                    2. Click apply to submit an application
                    <br />
                    3. Take our Course Readiness quiz. Click this{' '}
                    <span className="cursor-pointer text-blue-500 underline">
                      <a
                        href={'https://forms.gle/Y8cWxnZVRYi7beq7A'}
                        target="_blank"
                        without
                        rel="noreferrer"
                      >
                        link
                      </a>
                    </span>
                    <br />
                    4. Pay within the deadline to secure your spot
                  </p>
                  <br />
                  <p
                    className="whitespace-pre-line text-justify text-xs lg:text-sm"
                    style={{ lineHeight: '1.55rem' }}
                  >
                    <span className="text-blue-500 underline">
                      <Link to="/contact-us">Contact us</Link>
                    </span>{' '}
                    if you have any further questions!
                  </p>
                </div>
                <hr className="border-1 mx-4 my-5 border-adx-darkerGray" />

                <div className="my-2 mt-5 space-y-1 px-7">
                  <p className="text-xs font-semibold lg:text-sm">Description</p>
                  <p className="whitespace-pre-line text-justify text-xs lg:text-sm">
                    {courseAdvertisement.course_description}
                  </p>
                </div>
                <hr className="border-1 mx-4 my-5 border-adx-darkerGray" />

                <div className="my-2 space-y-2 px-7">
                  <p className="text-xs font-semibold lg:text-sm">Benefits</p>
                  <p
                    className="whitespace-pre-line text-xs lg:text-sm"
                    style={{ lineHeight: '1.55rem' }}
                  >
                    {courseAdvertisement.course_benefits}
                  </p>
                </div>
                <hr className="border-1 mx-4 my-5 border-adx-darkerGray" />

                <div className="my-2 space-y-2 px-7">
                  <p className="text-xs font-semibold lg:text-sm">Requirements</p>
                  <p
                    className="whitespace-pre-line text-xs lg:text-sm"
                    style={{ lineHeight: '1.55rem' }}
                  >
                    {courseAdvertisement.course_requirements}
                  </p>
                </div>
                <hr className="border-1 mx-4 my-5 border-adx-darkerGray" />

                <div className="my-2 space-y-2 px-7">
                  <p className="text-xs font-semibold lg:text-sm">Career Paths</p>
                  <p
                    className="whitespace-pre-line text-xs lg:text-sm"
                    style={{ lineHeight: '1.55rem' }}
                  >
                    {courseAdvertisement.course_careers}
                  </p>
                </div>
                <hr className="border-1 mx-4 my-5 border-adx-darkerGray" />

                <div className="my-2 space-y-2 px-7">
                  <p className="text-xs font-semibold lg:text-sm">Teaching Method</p>
                  <p
                    className="whitespace-pre-line text-xs lg:text-sm"
                    style={{ lineHeight: '1.55rem' }}
                  >
                    {courseAdvertisement.course_teaching_method}
                  </p>
                </div>
                <hr className="border-1 mx-4 my-5 border-adx-darkerGray" />

                <div className="my-2 space-y-2 px-7">
                  <p className="text-xs font-semibold lg:text-sm">Intake</p>
                  {courseAdvertisement.nex_edu_course_intakes?.map((item, index) => {
                    const start_date = moment(item.start_date).format('DD MMMM YYYY')
                    return (
                      <p
                        key={index}
                        className="whitespace-pre-line text-justify text-xs lg:text-sm"
                        style={{ lineHeight: '1.55rem' }}
                      >
                        {start_date}
                      </p>
                    )
                  })}
                  <p
                    className="whitespace-pre-line text-justify text-xs lg:text-sm"
                    style={{ lineHeight: '1.55rem' }}
                  >
                    More intakes to open in the future!
                  </p>
                </div>
                <hr className="border-1 mx-4 my-5 border-adx-darkerGray" />

                <div className="my-2 space-y-2 px-7">
                  <p className="text-xs font-semibold lg:text-sm">Length of Course</p>
                  <p
                    className="whitespace-pre-line text-justify text-xs lg:text-sm"
                    style={{ lineHeight: '1.55rem' }}
                  >
                    {courseAdvertisement.course_length}
                  </p>
                </div>
                <hr className="border-1 mx-4 my-5 border-adx-darkerGray" />
                {courseAdvertisement?.nex_edu_course_testimonials && (
                  <>
                    <div className="my-2 space-y-2 px-7">
                      <p className="text-xs font-semibold lg:text-sm">Testimonials</p>
                      <video width="750" height="500" controls>
                        <source
                          src={courseAdvertisement?.nex_edu_course_testimonials}
                          type="video/mp4"
                        />
                      </video>
                    </div>
                    <hr className="border-1 mx-4 my-5 border-adx-darkerGray" />
                  </>
                )}
                <div className="my-2 space-y-2 px-7">
                  <p className="text-xs font-semibold lg:text-sm">Fee</p>
                  {/* TEMPORARILY ADDED UNTIL API IS READY */}
                  {courseAdvertisement?.trainer_name === 'Norma Liyana Omar' ? (
                    <>
                      <p
                        className="whitespace-pre-line text-justify text-xs lg:text-sm"
                        style={{ lineHeight: '1.55rem' }}
                      >
                        {`RM4000 for a one-off payment or`}
                      </p>

                      <p
                        className="whitespace-pre-line text-justify text-xs lg:text-sm"
                        style={{ lineHeight: '1.55rem' }}
                      >
                        {`RM500 first payment, then RM500 x 8 via installments`}
                      </p>
                    </>
                  ) : (
                    <>
                      <p
                        className="whitespace-pre-line text-justify text-xs lg:text-sm"
                        style={{ lineHeight: '1.55rem' }}
                      >
                        {`RM${
                          parseInt(courseAdvertisement.course_fee) -
                          courseAdvertisement.lump_sum_discount
                        } for a one-off payment`}
                      </p>
                      <p
                        className="whitespace-pre-line text-justify text-xs lg:text-sm"
                        style={{ lineHeight: '1.55rem' }}
                      >
                        {`RM${parseInt(
                          courseAdvertisement.course_fee / courseAdvertisement.num_of_installments
                        )} x ${courseAdvertisement.num_of_installments} via installments`}
                      </p>
                    </>
                  )}
                </div>
              </div>
            )}

            <div className="bg-white pt-16 pb-12">
              <div className="" id="lessonPlanContainer">
                <p className="mb-4 text-xs font-semibold lg:text-lg">Browse Lesson Plan</p>
                {courseAdvertisement?.nex_edu_course_topics?.map((item, index) => (
                  <Accordion
                    disabled={index === course_structure.data.length - 1}
                    key={index}
                    className={`${classes.accordionBox} ${classes.disableAccordion}`}
                    expanded={expandedPanel === index}
                    onChange={handleAccordionChange(index)}
                  >
                    <AccordionSummary
                      className={`${classes.accordionTitle} ${classes.disableAccordionSummary}`}
                      expandIcon={
                        index === course_structure.data.length - 1 ? null : <ExpandMoreIcon />
                      }
                    >
                      <p className="text-xs font-semibold lg:text-sm">{item.topic_title}</p>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDescription}>
                      <p
                        className="whitespace-pre-line text-xs lg:text-sm"
                        style={{ lineHeight: '1.55rem' }}
                      >
                        {item.topic_description}
                      </p>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
            </div>
          </div>
          {/* Joined Waitlist Popper to remove from waitlist */}
          <Popper
            open={openPopper}
            anchorEl={anchorRefPopper.current}
            transition
            disablePortal
            placement="bottom-end"
            className={`z-50 w-4/12 min-w-min max-w-sm rounded-xl shadow-xl lg:w-auto lg:max-w-sm ${classes.popperTwo}`}
          >
            {({ TransitionProps }) => (
              <Grow {...TransitionProps} style={{ transformOrigin: 'right top' }}>
                <div className="rounded-xl bg-white shadow-xl">
                  <ClickAwayListener onClickAway={handleDismissPopperModal}>
                    <MenuList autoFocusItem={openPopper}>
                      <MenuItem onClick={handleRemoveWaitlist} className={classes.settingsEDUBtn}>
                        Remove from waitlist
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </div>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
    </div>
  )
}

export default CourseApplicationDetails
